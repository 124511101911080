import { baConfig } from '../../config';
import { DataSourcesApi, DefaultApi } from '../../generated/data-core-svc';
import { store } from '../store';

class DataCoreApiManager {
  private static defaultApiInstance: DefaultApi;
  private static dataSourcesApiInstance: DataSourcesApi;

  /**
   * Normalize API URL to prevent double slashes
   * @param url The URL to normalize
   * @returns Normalized URL without trailing slash
   */
  private static normalizeApiUrl(url: string | undefined): string {
    if (!url) {
      return '';
    }
    // Remove trailing slash to prevent double slashes when combined with endpoint paths
    return url.endsWith('/') ? url.slice(0, -1) : url;
  }

  /**
   * Gets the DefaultApi instance
   * @returns DefaultApi instance
   */
  public static getInstance(): DefaultApi {
    if (!DataCoreApiManager.defaultApiInstance) {
      // Always use api3Url for non-datasource operations to ensure consistent API versioning
      // Feed endpoints should use the /orgs/{orgId}/... prefix (without v3)
      const apiUrl = baConfig.api3Url;
      const normalizedUrl = this.normalizeApiUrl(apiUrl);

      DataCoreApiManager.defaultApiInstance = new DefaultApi(undefined, normalizedUrl);
    }
    return DataCoreApiManager.defaultApiInstance;
  }

  /**
   * Gets the DataSourcesApi instance
   * @returns DataSourcesApi instance
   */
  public static getDataSourcesApi(): DataSourcesApi {
    if (!DataCoreApiManager.dataSourcesApiInstance) {
      // Check if the org has the "import-v3" feature flag
      const hasImportV3Flag = store.getters.features && store.getters.features['import-v3'] === 'true';

      // Use api3Url if the feature flag is enabled, otherwise use apiUrl
      // (temp workaround till all orgs are migrated to data-core deploy in prod-a)
      const apiUrl = hasImportV3Flag ? baConfig.api3Url : baConfig.apiUrl;
      const normalizedUrl = this.normalizeApiUrl(apiUrl);

      DataCoreApiManager.dataSourcesApiInstance = new DataSourcesApi(undefined, normalizedUrl);
    }
    return DataCoreApiManager.dataSourcesApiInstance;
  }
}

export default DataCoreApiManager;

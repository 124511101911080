/* tslint:disable */
/* eslint-disable */
/**
 * Data Core Service API
 * API for managing data sources, feeds, and imports
 *
 * The version of the OpenAPI document: v1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 *
 * @export
 * @interface DataCoreSvcAuthActor
 */
export interface DataCoreSvcAuthActor {
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcAuthActor
   */
  actingAsPrincipalId?: string | null;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcAuthActor
   */
  principalId?: string;
}
/**
 *
 * @export
 * @interface DataCoreSvcColumnsColumn
 */
export interface DataCoreSvcColumnsColumn {
  /**
   *
   * @type {DataCoreSvcDatafieldsDataFieldUnion}
   * @memberof DataCoreSvcColumnsColumn
   */
  field: DataCoreSvcDatafieldsDataFieldUnion;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcColumnsColumn
   */
  name: string;
}
/**
 *
 * @export
 * @interface DataCoreSvcCreatedsdtosCreateBigQueryDataSource
 */
export interface DataCoreSvcCreatedsdtosCreateBigQueryDataSource {
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcCreatedsdtosCreateBigQueryDataSource
   */
  datasetId: string;
  /**
   *
   * @type {number}
   * @memberof DataCoreSvcCreatedsdtosCreateBigQueryDataSource
   */
  expiresDays?: number | null;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcCreatedsdtosCreateBigQueryDataSource
   */
  feature?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcCreatedsdtosCreateBigQueryDataSource
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcCreatedsdtosCreateBigQueryDataSource
   */
  projectId: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcCreatedsdtosCreateBigQueryDataSource
   */
  region: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcCreatedsdtosCreateBigQueryDataSource
   */
  table: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcCreatedsdtosCreateBigQueryDataSource
   */
  type: string;
}
/**
 *
 * @export
 * @interface DataCoreSvcCreatedsdtosCreateDataSourceUnion
 */
export interface DataCoreSvcCreatedsdtosCreateDataSourceUnion {
  /**
   *
   * @type {DataCoreSvcCreatedsdtosCreateBigQueryDataSource}
   * @memberof DataCoreSvcCreatedsdtosCreateDataSourceUnion
   */
  bigquery?: DataCoreSvcCreatedsdtosCreateBigQueryDataSource;
  /**
   *
   * @type {DataCoreSvcCreatedsdtosCreateGcsDataSource}
   * @memberof DataCoreSvcCreatedsdtosCreateDataSourceUnion
   */
  gcs?: DataCoreSvcCreatedsdtosCreateGcsDataSource;
  /**
   *
   * @type {DataCoreSvcCreatedsdtosCreateGoogleSheetDataSource}
   * @memberof DataCoreSvcCreatedsdtosCreateDataSourceUnion
   */
  'google-sheet'?: DataCoreSvcCreatedsdtosCreateGoogleSheetDataSource;
  /**
   *
   * @type {DataCoreSvcCreatedsdtosCreateTransformDataSource}
   * @memberof DataCoreSvcCreatedsdtosCreateDataSourceUnion
   */
  transform?: DataCoreSvcCreatedsdtosCreateTransformDataSource;
}
/**
 *
 * @export
 * @interface DataCoreSvcCreatedsdtosCreateGcsDataSource
 */
export interface DataCoreSvcCreatedsdtosCreateGcsDataSource {
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcCreatedsdtosCreateGcsDataSource
   */
  bucket: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcCreatedsdtosCreateGcsDataSource
   */
  contentType: string;
  /**
   *
   * @type {number}
   * @memberof DataCoreSvcCreatedsdtosCreateGcsDataSource
   */
  expiresDays?: number | null;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcCreatedsdtosCreateGcsDataSource
   */
  feature?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcCreatedsdtosCreateGcsDataSource
   */
  fileId: string;
  /**
   *
   * @type {boolean}
   * @memberof DataCoreSvcCreatedsdtosCreateGcsDataSource
   */
  jaggedRows: boolean;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcCreatedsdtosCreateGcsDataSource
   */
  name: string;
  /**
   *
   * @type {Array<DataCoreSvcCreatedsdtosSchemaField>}
   * @memberof DataCoreSvcCreatedsdtosCreateGcsDataSource
   */
  schema?: Array<DataCoreSvcCreatedsdtosSchemaField>;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcCreatedsdtosCreateGcsDataSource
   */
  sheetRange: string;
  /**
   *
   * @type {number}
   * @memberof DataCoreSvcCreatedsdtosCreateGcsDataSource
   */
  skipLeadingRows: number;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcCreatedsdtosCreateGcsDataSource
   */
  type: string;
}
/**
 *
 * @export
 * @interface DataCoreSvcCreatedsdtosCreateGoogleSheetDataSource
 */
export interface DataCoreSvcCreatedsdtosCreateGoogleSheetDataSource {
  /**
   *
   * @type {number}
   * @memberof DataCoreSvcCreatedsdtosCreateGoogleSheetDataSource
   */
  expiresDays?: number | null;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcCreatedsdtosCreateGoogleSheetDataSource
   */
  feature?: string;
  /**
   *
   * @type {boolean}
   * @memberof DataCoreSvcCreatedsdtosCreateGoogleSheetDataSource
   */
  jaggedRows: boolean;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcCreatedsdtosCreateGoogleSheetDataSource
   */
  name: string;
  /**
   *
   * @type {Array<DataCoreSvcCreatedsdtosSchemaField>}
   * @memberof DataCoreSvcCreatedsdtosCreateGoogleSheetDataSource
   */
  schema?: Array<DataCoreSvcCreatedsdtosSchemaField>;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcCreatedsdtosCreateGoogleSheetDataSource
   */
  sheetRange: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcCreatedsdtosCreateGoogleSheetDataSource
   */
  sheetUri: string;
  /**
   *
   * @type {number}
   * @memberof DataCoreSvcCreatedsdtosCreateGoogleSheetDataSource
   */
  skipLeadingRows: number;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcCreatedsdtosCreateGoogleSheetDataSource
   */
  type: string;
}
/**
 *
 * @export
 * @interface DataCoreSvcCreatedsdtosCreateTransformDataSource
 */
export interface DataCoreSvcCreatedsdtosCreateTransformDataSource {
  /**
   *
   * @type {number}
   * @memberof DataCoreSvcCreatedsdtosCreateTransformDataSource
   */
  expiresDays?: number | null;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcCreatedsdtosCreateTransformDataSource
   */
  feature?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcCreatedsdtosCreateTransformDataSource
   */
  name: string;
  /**
   *
   * @type {Array<object>}
   * @memberof DataCoreSvcCreatedsdtosCreateTransformDataSource
   */
  params: Array<object> | null;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcCreatedsdtosCreateTransformDataSource
   */
  parentDataSourceId: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcCreatedsdtosCreateTransformDataSource
   */
  query: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcCreatedsdtosCreateTransformDataSource
   */
  transformType?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcCreatedsdtosCreateTransformDataSource
   */
  type: string;
}
/**
 *
 * @export
 * @interface DataCoreSvcCreatedsdtosSchemaField
 */
export interface DataCoreSvcCreatedsdtosSchemaField {
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcCreatedsdtosSchemaField
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcCreatedsdtosSchemaField
   */
  type: string;
}
/**
 *
 * @export
 * @interface DataCoreSvcCreatedsdtosUnsafeJoinDto
 */
export interface DataCoreSvcCreatedsdtosUnsafeJoinDto {
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcCreatedsdtosUnsafeJoinDto
   */
  joinType?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcCreatedsdtosUnsafeJoinDto
   */
  leftColumnName: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcCreatedsdtosUnsafeJoinDto
   */
  leftDataSourceId: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcCreatedsdtosUnsafeJoinDto
   */
  operator?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcCreatedsdtosUnsafeJoinDto
   */
  rightColumnName: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcCreatedsdtosUnsafeJoinDto
   */
  rightDataSourceId: string;
}
/**
 *
 * @export
 * @interface DataCoreSvcCreatedsdtosUnsafeTransform
 */
export interface DataCoreSvcCreatedsdtosUnsafeTransform {
  /**
   *
   * @type {Array<DataCoreSvcFiltersFilterUnion>}
   * @memberof DataCoreSvcCreatedsdtosUnsafeTransform
   */
  filters?: Array<DataCoreSvcFiltersFilterUnion> | null;
  /**
   *
   * @type {Array<DataCoreSvcCreatedsdtosUnsafeJoinDto>}
   * @memberof DataCoreSvcCreatedsdtosUnsafeTransform
   */
  joins?: Array<DataCoreSvcCreatedsdtosUnsafeJoinDto> | null;
  /**
   *
   * @type {Array<DataCoreSvcOrderbysOrderBy>}
   * @memberof DataCoreSvcCreatedsdtosUnsafeTransform
   */
  orderBys?: Array<DataCoreSvcOrderbysOrderBy> | null;
  /**
   *
   * @type {Array<DataCoreSvcColumnsColumn>}
   * @memberof DataCoreSvcCreatedsdtosUnsafeTransform
   */
  selectedFields?: Array<DataCoreSvcColumnsColumn> | null;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcCreatedsdtosUnsafeTransform
   */
  targetSchemaId?: string | null;
}
/**
 *
 * @export
 * @interface DataCoreSvcDatafieldsConcatCalculatedFieldData
 */
export interface DataCoreSvcDatafieldsConcatCalculatedFieldData {
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatafieldsConcatCalculatedFieldData
   */
  delimiter?: string;
  /**
   *
   * @type {Array<DataCoreSvcDatafieldsDataFieldUnion>}
   * @memberof DataCoreSvcDatafieldsConcatCalculatedFieldData
   */
  inputFields: Array<DataCoreSvcDatafieldsDataFieldUnion> | null;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatafieldsConcatCalculatedFieldData
   */
  type?: DataCoreSvcDatafieldsConcatCalculatedFieldDataTypeEnum;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatafieldsConcatCalculatedFieldData
   */
  valueType?: string;
}

export const DataCoreSvcDatafieldsConcatCalculatedFieldDataTypeEnum = {
  Concat: 'concat',
} as const;

export type DataCoreSvcDatafieldsConcatCalculatedFieldDataTypeEnum =
  typeof DataCoreSvcDatafieldsConcatCalculatedFieldDataTypeEnum[keyof typeof DataCoreSvcDatafieldsConcatCalculatedFieldDataTypeEnum];

/**
 *
 * @export
 * @interface DataCoreSvcDatafieldsConstantFieldData
 */
export interface DataCoreSvcDatafieldsConstantFieldData {
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatafieldsConstantFieldData
   */
  type?: DataCoreSvcDatafieldsConstantFieldDataTypeEnum;
  /**
   *
   * @type {any}
   * @memberof DataCoreSvcDatafieldsConstantFieldData
   */
  value?: any | null;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatafieldsConstantFieldData
   */
  valueType?: string;
}

export const DataCoreSvcDatafieldsConstantFieldDataTypeEnum = {
  Constant: 'constant',
} as const;

export type DataCoreSvcDatafieldsConstantFieldDataTypeEnum =
  typeof DataCoreSvcDatafieldsConstantFieldDataTypeEnum[keyof typeof DataCoreSvcDatafieldsConstantFieldDataTypeEnum];

/**
 * @type DataCoreSvcDatafieldsDataFieldUnion
 * @export
 */
export type DataCoreSvcDatafieldsDataFieldUnion =
  | DataCoreSvcDatafieldsConcatCalculatedFieldData
  | DataCoreSvcDatafieldsConstantFieldData
  | DataCoreSvcDatafieldsExistingFieldData
  | DataCoreSvcDatafieldsJsonExtractFieldData
  | DataCoreSvcDatafieldsMathFieldData;

/**
 *
 * @export
 * @interface DataCoreSvcDatafieldsExistingFieldData
 */
export interface DataCoreSvcDatafieldsExistingFieldData {
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatafieldsExistingFieldData
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatafieldsExistingFieldData
   */
  type?: DataCoreSvcDatafieldsExistingFieldDataTypeEnum;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatafieldsExistingFieldData
   */
  valueType?: string;
}

export const DataCoreSvcDatafieldsExistingFieldDataTypeEnum = {
  Existing: 'existing',
} as const;

export type DataCoreSvcDatafieldsExistingFieldDataTypeEnum =
  typeof DataCoreSvcDatafieldsExistingFieldDataTypeEnum[keyof typeof DataCoreSvcDatafieldsExistingFieldDataTypeEnum];

/**
 *
 * @export
 * @interface DataCoreSvcDatafieldsJsonExtractFieldData
 */
export interface DataCoreSvcDatafieldsJsonExtractFieldData {
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatafieldsJsonExtractFieldData
   */
  jsonPath: string;
  /**
   *
   * @type {any}
   * @memberof DataCoreSvcDatafieldsJsonExtractFieldData
   */
  sourceField: any;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatafieldsJsonExtractFieldData
   */
  type?: DataCoreSvcDatafieldsJsonExtractFieldDataTypeEnum;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatafieldsJsonExtractFieldData
   */
  valueType?: string;
}

export const DataCoreSvcDatafieldsJsonExtractFieldDataTypeEnum = {
  StringFromJson: 'stringFromJson',
} as const;

export type DataCoreSvcDatafieldsJsonExtractFieldDataTypeEnum =
  typeof DataCoreSvcDatafieldsJsonExtractFieldDataTypeEnum[keyof typeof DataCoreSvcDatafieldsJsonExtractFieldDataTypeEnum];

/**
 *
 * @export
 * @interface DataCoreSvcDatafieldsMathFieldData
 */
export interface DataCoreSvcDatafieldsMathFieldData {
  /**
   *
   * @type {any}
   * @memberof DataCoreSvcDatafieldsMathFieldData
   */
  left: any;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatafieldsMathFieldData
   */
  operator: string;
  /**
   *
   * @type {any}
   * @memberof DataCoreSvcDatafieldsMathFieldData
   */
  right: any;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatafieldsMathFieldData
   */
  type?: DataCoreSvcDatafieldsMathFieldDataTypeEnum;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatafieldsMathFieldData
   */
  valuetype?: string;
}

export const DataCoreSvcDatafieldsMathFieldDataTypeEnum = {
  Math: 'math',
} as const;

export type DataCoreSvcDatafieldsMathFieldDataTypeEnum =
  typeof DataCoreSvcDatafieldsMathFieldDataTypeEnum[keyof typeof DataCoreSvcDatafieldsMathFieldDataTypeEnum];

/**
 *
 * @export
 * @interface DataCoreSvcDatasourcehdlBqTablePointerOutput
 */
export interface DataCoreSvcDatasourcehdlBqTablePointerOutput {
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcehdlBqTablePointerOutput
   */
  dataSetId?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcehdlBqTablePointerOutput
   */
  projectId?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcehdlBqTablePointerOutput
   */
  region?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcehdlBqTablePointerOutput
   */
  table?: string;
}
/**
 *
 * @export
 * @interface DataCoreSvcDatasourcehdlCreateInput
 */
export interface DataCoreSvcDatasourcehdlCreateInput {
  /**
   *
   * @type {DataCoreSvcCreatedsdtosCreateDataSourceUnion}
   * @memberof DataCoreSvcDatasourcehdlCreateInput
   */
  data?: DataCoreSvcCreatedsdtosCreateDataSourceUnion;
}
/**
 *
 * @export
 * @interface DataCoreSvcDatasourcehdlCsvUploadUrlOutput
 */
export interface DataCoreSvcDatasourcehdlCsvUploadUrlOutput {
  /**
   *
   * @type {DataCoreSvcFilesvcFilePointer}
   * @memberof DataCoreSvcDatasourcehdlCsvUploadUrlOutput
   */
  filePointer?: DataCoreSvcFilesvcFilePointer;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcehdlCsvUploadUrlOutput
   */
  uploadUrl?: string;
}
/**
 *
 * @export
 * @interface DataCoreSvcDatasourcehdlDownloadOutput
 */
export interface DataCoreSvcDatasourcehdlDownloadOutput {
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcehdlDownloadOutput
   */
  link?: string | null;
}
/**
 *
 * @export
 * @interface DataCoreSvcDatasourcehdlListOutput
 */
export interface DataCoreSvcDatasourcehdlListOutput {
  /**
   *
   * @type {Array<DataCoreSvcDatasourcesDataSourcePointer>}
   * @memberof DataCoreSvcDatasourcehdlListOutput
   */
  items?: Array<DataCoreSvcDatasourcesDataSourcePointer> | null;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcehdlListOutput
   */
  pageToken?: string | null;
}
/**
 *
 * @export
 * @interface DataCoreSvcDatasourcehdlListPaginatedOutput
 */
export interface DataCoreSvcDatasourcehdlListPaginatedOutput {
  /**
   *
   * @type {Array<DataCoreSvcDatasourcesDataSourcePointer>}
   * @memberof DataCoreSvcDatasourcehdlListPaginatedOutput
   */
  items?: Array<DataCoreSvcDatasourcesDataSourcePointer> | null;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcehdlListPaginatedOutput
   */
  pageToken?: string | null;
}
/**
 *
 * @export
 * @interface DataCoreSvcDatasourcehdlRowsInput
 */
export interface DataCoreSvcDatasourcehdlRowsInput {
  /**
   *
   * @type {Array<DataCoreSvcColumnsColumn>}
   * @memberof DataCoreSvcDatasourcehdlRowsInput
   */
  columns?: Array<DataCoreSvcColumnsColumn> | null;
  /**
   *
   * @type {Array<DataCoreSvcFiltersFilterUnion>}
   * @memberof DataCoreSvcDatasourcehdlRowsInput
   */
  filters?: Array<DataCoreSvcFiltersFilterUnion> | null;
  /**
   *
   * @type {Array<DataCoreSvcCreatedsdtosUnsafeJoinDto>}
   * @memberof DataCoreSvcDatasourcehdlRowsInput
   */
  joins?: Array<DataCoreSvcCreatedsdtosUnsafeJoinDto> | null;
  /**
   *
   * @type {Array<DataCoreSvcOrderbysOrderBy>}
   * @memberof DataCoreSvcDatasourcehdlRowsInput
   */
  orderBys?: Array<DataCoreSvcOrderbysOrderBy> | null;
  /**
   *
   * @type {boolean}
   * @memberof DataCoreSvcDatasourcehdlRowsInput
   */
  stringifyComplexTypes?: boolean;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcehdlRowsInput
   */
  targetSchemaId?: string;
}
/**
 *
 * @export
 * @interface DataCoreSvcDatasourcehdlRowsOutput
 */
export interface DataCoreSvcDatasourcehdlRowsOutput {
  /**
   *
   * @type {DataCoreSvcCreatedsdtosUnsafeTransform}
   * @memberof DataCoreSvcDatasourcehdlRowsOutput
   */
  appliedTransform?: DataCoreSvcCreatedsdtosUnsafeTransform;
  /**
   *
   * @type {Array<DataCoreSvcDomainField>}
   * @memberof DataCoreSvcDatasourcehdlRowsOutput
   */
  baseSchema?: Array<DataCoreSvcDomainField> | null;
  /**
   *
   * @type {number}
   * @memberof DataCoreSvcDatasourcehdlRowsOutput
   */
  dataLength?: number;
  /**
   *
   * @type {Array<any>}
   * @memberof DataCoreSvcDatasourcehdlRowsOutput
   */
  items?: Array<any> | null;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcehdlRowsOutput
   */
  pageToken?: string | null;
  /**
   *
   * @type {number}
   * @memberof DataCoreSvcDatasourcehdlRowsOutput
   */
  totalRows?: number;
  /**
   *
   * @type {Array<DataCoreSvcDomainField>}
   * @memberof DataCoreSvcDatasourcehdlRowsOutput
   */
  transformedSchema?: Array<DataCoreSvcDomainField> | null;
  /**
   *
   * @type {boolean}
   * @memberof DataCoreSvcDatasourcehdlRowsOutput
   */
  truncated?: boolean;
}
/**
 *
 * @export
 * @interface DataCoreSvcDatasourcehdlSchemaOutput
 */
export interface DataCoreSvcDatasourcehdlSchemaOutput {
  /**
   *
   * @type {Array<DataCoreSvcDomainField>}
   * @memberof DataCoreSvcDatasourcehdlSchemaOutput
   */
  items?: Array<DataCoreSvcDomainField> | null;
}
/**
 *
 * @export
 * @interface DataCoreSvcDatasourcesBigQueryTableDataSource
 */
export interface DataCoreSvcDatasourcesBigQueryTableDataSource {
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcesBigQueryTableDataSource
   */
  createdAt?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcesBigQueryTableDataSource
   */
  datasetId?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof DataCoreSvcDatasourcesBigQueryTableDataSource
   */
  errors?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof DataCoreSvcDatasourcesBigQueryTableDataSource
   */
  expiresAtSEC?: number | null;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcesBigQueryTableDataSource
   */
  feature?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcesBigQueryTableDataSource
   */
  id?: string | null;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcesBigQueryTableDataSource
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcesBigQueryTableDataSource
   */
  orgId?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcesBigQueryTableDataSource
   */
  projectId?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcesBigQueryTableDataSource
   */
  region?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcesBigQueryTableDataSource
   */
  schemaId?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcesBigQueryTableDataSource
   */
  status?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcesBigQueryTableDataSource
   */
  table?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcesBigQueryTableDataSource
   */
  type?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcesBigQueryTableDataSource
   */
  updatedAt?: string;
}
/**
 *
 * @export
 * @interface DataCoreSvcDatasourcesDataSourcePointer
 */
export interface DataCoreSvcDatasourcesDataSourcePointer {
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcesDataSourcePointer
   */
  feature?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcesDataSourcePointer
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcesDataSourcePointer
   */
  name?: string;
  /**
   *
   * @type {number}
   * @memberof DataCoreSvcDatasourcesDataSourcePointer
   */
  omitempty?: number | null;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcesDataSourcePointer
   */
  type?: string;
}
/**
 * @type DataCoreSvcDatasourcesDataSourceUnion
 * @export
 */
export type DataCoreSvcDatasourcesDataSourceUnion =
  | DataCoreSvcDatasourcesBigQueryTableDataSource
  | DataCoreSvcDatasourcesGcsDataSource
  | DataCoreSvcDatasourcesGoogleSheetDataSource
  | DataCoreSvcDatasourcesTransformDataSource;

/**
 *
 * @export
 * @interface DataCoreSvcDatasourcesDataSourceValidationResult
 */
export interface DataCoreSvcDatasourcesDataSourceValidationResult {
  /**
   *
   * @type {Array<DataCoreSvcDomainField>}
   * @memberof DataCoreSvcDatasourcesDataSourceValidationResult
   */
  columns?: Array<DataCoreSvcDomainField> | null;
  /**
   *
   * @type {Array<string>}
   * @memberof DataCoreSvcDatasourcesDataSourceValidationResult
   */
  errors?: Array<string> | null;
  /**
   *
   * @type {Array<DataCoreSvcDomainSchemaPointer>}
   * @memberof DataCoreSvcDatasourcesDataSourceValidationResult
   */
  matchingGlobalSchemas?: Array<DataCoreSvcDomainSchemaPointer> | null;
  /**
   *
   * @type {Array<any>}
   * @memberof DataCoreSvcDatasourcesDataSourceValidationResult
   */
  rowPreview?: Array<any> | null;
}
/**
 *
 * @export
 * @interface DataCoreSvcDatasourcesGcsDataSource
 */
export interface DataCoreSvcDatasourcesGcsDataSource {
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcesGcsDataSource
   */
  Range?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcesGcsDataSource
   */
  bucket?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcesGcsDataSource
   */
  contentType?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcesGcsDataSource
   */
  createdAt?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcesGcsDataSource
   */
  datasetId?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof DataCoreSvcDatasourcesGcsDataSource
   */
  errors?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof DataCoreSvcDatasourcesGcsDataSource
   */
  expiresAtSEC?: number | null;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcesGcsDataSource
   */
  feature?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcesGcsDataSource
   */
  fileId?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcesGcsDataSource
   */
  id?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof DataCoreSvcDatasourcesGcsDataSource
   */
  jaggedRows?: boolean;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcesGcsDataSource
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcesGcsDataSource
   */
  orgId?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcesGcsDataSource
   */
  projectId?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcesGcsDataSource
   */
  region?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcesGcsDataSource
   */
  schemaId?: string;
  /**
   *
   * @type {number}
   * @memberof DataCoreSvcDatasourcesGcsDataSource
   */
  skipLeadingRows?: number;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcesGcsDataSource
   */
  status?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcesGcsDataSource
   */
  table?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcesGcsDataSource
   */
  type?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcesGcsDataSource
   */
  updatedAt?: string;
}
/**
 *
 * @export
 * @interface DataCoreSvcDatasourcesGoogleSheetDataSource
 */
export interface DataCoreSvcDatasourcesGoogleSheetDataSource {
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcesGoogleSheetDataSource
   */
  createdAt?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcesGoogleSheetDataSource
   */
  datasetId?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof DataCoreSvcDatasourcesGoogleSheetDataSource
   */
  errors?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof DataCoreSvcDatasourcesGoogleSheetDataSource
   */
  expiresAtSEC?: number | null;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcesGoogleSheetDataSource
   */
  feature?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcesGoogleSheetDataSource
   */
  id?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof DataCoreSvcDatasourcesGoogleSheetDataSource
   */
  jaggedRows?: boolean;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcesGoogleSheetDataSource
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcesGoogleSheetDataSource
   */
  orgId?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcesGoogleSheetDataSource
   */
  projectId?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcesGoogleSheetDataSource
   */
  region?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcesGoogleSheetDataSource
   */
  schemaId?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcesGoogleSheetDataSource
   */
  sheetRange?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcesGoogleSheetDataSource
   */
  sheetUri?: string;
  /**
   *
   * @type {number}
   * @memberof DataCoreSvcDatasourcesGoogleSheetDataSource
   */
  skipLeadingRows?: number;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcesGoogleSheetDataSource
   */
  status?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcesGoogleSheetDataSource
   */
  table?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcesGoogleSheetDataSource
   */
  type?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcesGoogleSheetDataSource
   */
  updatedAt?: string;
}
/**
 *
 * @export
 * @interface DataCoreSvcDatasourcesTransformDataSource
 */
export interface DataCoreSvcDatasourcesTransformDataSource {
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcesTransformDataSource
   */
  createdAt?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcesTransformDataSource
   */
  datasetId?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof DataCoreSvcDatasourcesTransformDataSource
   */
  errors?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof DataCoreSvcDatasourcesTransformDataSource
   */
  expiresAtSEC?: number | null;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcesTransformDataSource
   */
  feature?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcesTransformDataSource
   */
  id?: string | null;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcesTransformDataSource
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcesTransformDataSource
   */
  orgId?: string;
  /**
   *
   * @type {Array<object>}
   * @memberof DataCoreSvcDatasourcesTransformDataSource
   */
  params?: Array<object> | null;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcesTransformDataSource
   */
  projectId?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcesTransformDataSource
   */
  query?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcesTransformDataSource
   */
  region?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcesTransformDataSource
   */
  schemaId?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcesTransformDataSource
   */
  status?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcesTransformDataSource
   */
  table?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcesTransformDataSource
   */
  transformType?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcesTransformDataSource
   */
  type?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDatasourcesTransformDataSource
   */
  updatedAt?: string;
}
/**
 *
 * @export
 * @interface DataCoreSvcDomainField
 */
export interface DataCoreSvcDomainField {
  /**
   *
   * @type {boolean}
   * @memberof DataCoreSvcDomainField
   */
  allowFilter?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof DataCoreSvcDomainField
   */
  allowNull?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof DataCoreSvcDomainField
   */
  allowSort?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof DataCoreSvcDomainField
   */
  defaultTimestampField?: boolean;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDomainField
   */
  description?: string;
  /**
   *
   * @type {Array<DataCoreSvcDomainField>}
   * @memberof DataCoreSvcDomainField
   */
  fields?: Array<DataCoreSvcDomainField>;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDomainField
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDomainField
   */
  type?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDomainField
   */
  validation?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof DataCoreSvcDomainField
   */
  values?: Array<string>;
}
/**
 *
 * @export
 * @interface DataCoreSvcDomainSchemaPointer
 */
export interface DataCoreSvcDomainSchemaPointer {
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDomainSchemaPointer
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDomainSchemaPointer
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcDomainSchemaPointer
   */
  name?: string;
  /**
   *
   * @type {number}
   * @memberof DataCoreSvcDomainSchemaPointer
   */
  version?: number;
}
/**
 *
 * @export
 * @interface DataCoreSvcFeedhdlCreateFeedInput
 */
export interface DataCoreSvcFeedhdlCreateFeedInput {
  /**
   * Configuration for the feed
   * @type {{ [key: string]: any; }}
   * @memberof DataCoreSvcFeedhdlCreateFeedInput
   */
  configuration?: { [key: string]: any } | null;
  /**
   * ID of the data source to create the feed from
   * @type {string}
   * @memberof DataCoreSvcFeedhdlCreateFeedInput
   */
  datasourceId?: string;
}
/**
 *
 * @export
 * @interface DataCoreSvcFeedhdlCreateFeedOutput
 */
export interface DataCoreSvcFeedhdlCreateFeedOutput {
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedhdlCreateFeedOutput
   */
  feedId?: string;
}
/**
 *
 * @export
 * @interface DataCoreSvcFeedhdlFeedValidationError
 */
export interface DataCoreSvcFeedhdlFeedValidationError {
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedhdlFeedValidationError
   */
  code?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedhdlFeedValidationError
   */
  field?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedhdlFeedValidationError
   */
  message?: string;
}
/**
 *
 * @export
 * @interface DataCoreSvcFeedhdlFeedValidationOutput
 */
export interface DataCoreSvcFeedhdlFeedValidationOutput {
  /**
   *
   * @type {Array<DataCoreSvcFeedhdlFeedValidationError>}
   * @memberof DataCoreSvcFeedhdlFeedValidationOutput
   */
  errors?: Array<DataCoreSvcFeedhdlFeedValidationError>;
  /**
   *
   * @type {Array<{ [key: string]: any; }>}
   * @memberof DataCoreSvcFeedhdlFeedValidationOutput
   */
  previewData?: Array<{ [key: string]: any }>;
  /**
   *
   * @type {DataCoreSvcFeedhdlFeedValidationStatistics}
   * @memberof DataCoreSvcFeedhdlFeedValidationOutput
   */
  statistics?: DataCoreSvcFeedhdlFeedValidationStatistics;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedhdlFeedValidationOutput
   */
  status?: string;
  /**
   *
   * @type {Array<DataCoreSvcFeedhdlFeedValidationError>}
   * @memberof DataCoreSvcFeedhdlFeedValidationOutput
   */
  warnings?: Array<DataCoreSvcFeedhdlFeedValidationError>;
}
/**
 *
 * @export
 * @interface DataCoreSvcFeedhdlFeedValidationStatistics
 */
export interface DataCoreSvcFeedhdlFeedValidationStatistics {
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedhdlFeedValidationStatistics
   */
  endDate?: string;
  /**
   *
   * @type {number}
   * @memberof DataCoreSvcFeedhdlFeedValidationStatistics
   */
  invalidRows?: number;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedhdlFeedValidationStatistics
   */
  startDate?: string;
  /**
   *
   * @type {number}
   * @memberof DataCoreSvcFeedhdlFeedValidationStatistics
   */
  totalRows?: number;
  /**
   *
   * @type {number}
   * @memberof DataCoreSvcFeedhdlFeedValidationStatistics
   */
  validRows?: number;
}
/**
 *
 * @export
 * @interface DataCoreSvcFeedhdlGetFeedOutput
 */
export interface DataCoreSvcFeedhdlGetFeedOutput {
  /**
   *
   * @type {DataCoreSvcFeedsFeedAggregate}
   * @memberof DataCoreSvcFeedhdlGetFeedOutput
   */
  feedAggregate?: DataCoreSvcFeedsFeedAggregate;
}
/**
 *
 * @export
 * @interface DataCoreSvcFeedhdlListOutput
 */
export interface DataCoreSvcFeedhdlListOutput {
  /**
   *
   * @type {Array<DataCoreSvcFeedsFeedSummary>}
   * @memberof DataCoreSvcFeedhdlListOutput
   */
  items?: Array<DataCoreSvcFeedsFeedSummary> | null;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedhdlListOutput
   */
  pageToken?: string;
}
/**
 *
 * @export
 * @interface DataCoreSvcFeedhdlUpdateFeedApprovalInput
 */
export interface DataCoreSvcFeedhdlUpdateFeedApprovalInput {
  /**
   * Whether the feed is approved or rejected
   * @type {boolean}
   * @memberof DataCoreSvcFeedhdlUpdateFeedApprovalInput
   */
  approved?: boolean;
  /**
   * Reason for rejection (optional, only used when approved is false)
   * @type {string}
   * @memberof DataCoreSvcFeedhdlUpdateFeedApprovalInput
   */
  reason?: string;
}
/**
 *
 * @export
 * @interface DataCoreSvcFeedhdlUpdateFeedApprovalOutput
 */
export interface DataCoreSvcFeedhdlUpdateFeedApprovalOutput {
  /**
   *
   * @type {boolean}
   * @memberof DataCoreSvcFeedhdlUpdateFeedApprovalOutput
   */
  approved?: boolean;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedhdlUpdateFeedApprovalOutput
   */
  feedId?: string;
}
/**
 *
 * @export
 * @interface DataCoreSvcFeedrunhdlGetRunOutput
 */
export interface DataCoreSvcFeedrunhdlGetRunOutput {
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedrunhdlGetRunOutput
   */
  archivedAt?: string | null;
  /**
   *
   * @type {DataCoreSvcAuthActor}
   * @memberof DataCoreSvcFeedrunhdlGetRunOutput
   */
  archivedBy?: DataCoreSvcAuthActor;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedrunhdlGetRunOutput
   */
  createdAt?: string;
  /**
   *
   * @type {DataCoreSvcAuthActor}
   * @memberof DataCoreSvcFeedrunhdlGetRunOutput
   */
  createdBy?: DataCoreSvcAuthActor;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedrunhdlGetRunOutput
   */
  currentStepName?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedrunhdlGetRunOutput
   */
  endTime?: string | null;
  /**
   *
   * @type {number}
   * @memberof DataCoreSvcFeedrunhdlGetRunOutput
   */
  enumeratedItems?: number;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedrunhdlGetRunOutput
   */
  error?: string | null;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedrunhdlGetRunOutput
   */
  feature?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedrunhdlGetRunOutput
   */
  feedId?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedrunhdlGetRunOutput
   */
  feedRunId?: string;
  /**
   *
   * @type {boolean}
   * @memberof DataCoreSvcFeedrunhdlGetRunOutput
   */
  isFullyEnumerated?: boolean;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedrunhdlGetRunOutput
   */
  message?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedrunhdlGetRunOutput
   */
  orgId?: string;
  /**
   *
   * @type {any}
   * @memberof DataCoreSvcFeedrunhdlGetRunOutput
   */
  preview?: any;
  /**
   *
   * @type {number}
   * @memberof DataCoreSvcFeedrunhdlGetRunOutput
   */
  processedItems?: number;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedrunhdlGetRunOutput
   */
  startTime?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedrunhdlGetRunOutput
   */
  status?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof DataCoreSvcFeedrunhdlGetRunOutput
   */
  stepNames?: Array<string> | null;
  /**
   *
   * @type {DataCoreSvcFeedrunsFeedRunSummary}
   * @memberof DataCoreSvcFeedrunhdlGetRunOutput
   */
  summary?: DataCoreSvcFeedrunsFeedRunSummary;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedrunhdlGetRunOutput
   */
  type?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedrunhdlGetRunOutput
   */
  updatedAt?: string;
  /**
   *
   * @type {DataCoreSvcAuthActor}
   * @memberof DataCoreSvcFeedrunhdlGetRunOutput
   */
  updatedBy?: DataCoreSvcAuthActor;
  /**
   *
   * @type {Array<DataCoreSvcFeedrunsFeedRunUpdate>}
   * @memberof DataCoreSvcFeedrunhdlGetRunOutput
   */
  updates?: Array<DataCoreSvcFeedrunsFeedRunUpdate>;
}
/**
 *
 * @export
 * @interface DataCoreSvcFeedrunhdlGetRunSummaryOutput
 */
export interface DataCoreSvcFeedrunhdlGetRunSummaryOutput {
  /**
   *
   * @type {DataCoreSvcFeedrunsFeedRunAggregate}
   * @memberof DataCoreSvcFeedrunhdlGetRunSummaryOutput
   */
  feedRunAggregate?: DataCoreSvcFeedrunsFeedRunAggregate;
}
/**
 *
 * @export
 * @interface DataCoreSvcFeedrunhdlInitManualRunOutput
 */
export interface DataCoreSvcFeedrunhdlInitManualRunOutput {
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedrunhdlInitManualRunOutput
   */
  feedRunId?: string;
}
/**
 *
 * @export
 * @interface DataCoreSvcFeedrunhdlListRunsOutput
 */
export interface DataCoreSvcFeedrunhdlListRunsOutput {
  /**
   *
   * @type {Array<DataCoreSvcFeedrunsFeedRun>}
   * @memberof DataCoreSvcFeedrunhdlListRunsOutput
   */
  items?: Array<DataCoreSvcFeedrunsFeedRun> | null;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedrunhdlListRunsOutput
   */
  pageToken?: string;
}
/**
 *
 * @export
 * @interface DataCoreSvcFeedrunhdlStartManualRunOutput
 */
export interface DataCoreSvcFeedrunhdlStartManualRunOutput {
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedrunhdlStartManualRunOutput
   */
  feedRunId?: string;
}
/**
 *
 * @export
 * @interface DataCoreSvcFeedrunsFeedRun
 */
export interface DataCoreSvcFeedrunsFeedRun {
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedrunsFeedRun
   */
  archivedAt?: string | null;
  /**
   *
   * @type {DataCoreSvcAuthActor}
   * @memberof DataCoreSvcFeedrunsFeedRun
   */
  archivedBy?: DataCoreSvcAuthActor;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedrunsFeedRun
   */
  createdAt?: string;
  /**
   *
   * @type {DataCoreSvcAuthActor}
   * @memberof DataCoreSvcFeedrunsFeedRun
   */
  createdBy?: DataCoreSvcAuthActor;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedrunsFeedRun
   */
  currentStepName?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedrunsFeedRun
   */
  endTime?: string | null;
  /**
   *
   * @type {number}
   * @memberof DataCoreSvcFeedrunsFeedRun
   */
  enumeratedItems?: number;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedrunsFeedRun
   */
  error?: string | null;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedrunsFeedRun
   */
  feature?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedrunsFeedRun
   */
  feedId?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedrunsFeedRun
   */
  feedRunId?: string;
  /**
   *
   * @type {boolean}
   * @memberof DataCoreSvcFeedrunsFeedRun
   */
  isFullyEnumerated?: boolean;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedrunsFeedRun
   */
  message?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedrunsFeedRun
   */
  orgId?: string;
  /**
   *
   * @type {any}
   * @memberof DataCoreSvcFeedrunsFeedRun
   */
  preview?: any;
  /**
   *
   * @type {number}
   * @memberof DataCoreSvcFeedrunsFeedRun
   */
  processedItems?: number;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedrunsFeedRun
   */
  startTime?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedrunsFeedRun
   */
  status?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof DataCoreSvcFeedrunsFeedRun
   */
  stepNames?: Array<string> | null;
  /**
   *
   * @type {DataCoreSvcFeedrunsFeedRunSummary}
   * @memberof DataCoreSvcFeedrunsFeedRun
   */
  summary?: DataCoreSvcFeedrunsFeedRunSummary;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedrunsFeedRun
   */
  type?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedrunsFeedRun
   */
  updatedAt?: string;
  /**
   *
   * @type {DataCoreSvcAuthActor}
   * @memberof DataCoreSvcFeedrunsFeedRun
   */
  updatedBy?: DataCoreSvcAuthActor;
  /**
   *
   * @type {Array<DataCoreSvcFeedrunsFeedRunUpdate>}
   * @memberof DataCoreSvcFeedrunsFeedRun
   */
  updates?: Array<DataCoreSvcFeedrunsFeedRunUpdate>;
}
/**
 *
 * @export
 * @interface DataCoreSvcFeedrunsFeedRunAggregate
 */
export interface DataCoreSvcFeedrunsFeedRunAggregate {
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedrunsFeedRunAggregate
   */
  archivedAt?: string | null;
  /**
   *
   * @type {DataCoreSvcAuthActor}
   * @memberof DataCoreSvcFeedrunsFeedRunAggregate
   */
  archivedBy?: DataCoreSvcAuthActor;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedrunsFeedRunAggregate
   */
  createdAt?: string;
  /**
   *
   * @type {DataCoreSvcAuthActor}
   * @memberof DataCoreSvcFeedrunsFeedRunAggregate
   */
  createdBy?: DataCoreSvcAuthActor;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedrunsFeedRunAggregate
   */
  currentStepName?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedrunsFeedRunAggregate
   */
  endTime?: string | null;
  /**
   *
   * @type {number}
   * @memberof DataCoreSvcFeedrunsFeedRunAggregate
   */
  enumeratedItems?: number;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedrunsFeedRunAggregate
   */
  error?: string | null;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedrunsFeedRunAggregate
   */
  feature?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedrunsFeedRunAggregate
   */
  feedId?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedrunsFeedRunAggregate
   */
  feedRunId?: string;
  /**
   *
   * @type {boolean}
   * @memberof DataCoreSvcFeedrunsFeedRunAggregate
   */
  isFullyEnumerated?: boolean;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedrunsFeedRunAggregate
   */
  message?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedrunsFeedRunAggregate
   */
  orgId?: string;
  /**
   *
   * @type {any}
   * @memberof DataCoreSvcFeedrunsFeedRunAggregate
   */
  preview?: any;
  /**
   *
   * @type {number}
   * @memberof DataCoreSvcFeedrunsFeedRunAggregate
   */
  processedItems?: number;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedrunsFeedRunAggregate
   */
  startTime?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedrunsFeedRunAggregate
   */
  status?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof DataCoreSvcFeedrunsFeedRunAggregate
   */
  stepNames?: Array<string> | null;
  /**
   *
   * @type {DataCoreSvcFeedrunsFeedRunSummary}
   * @memberof DataCoreSvcFeedrunsFeedRunAggregate
   */
  summary?: DataCoreSvcFeedrunsFeedRunSummary;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedrunsFeedRunAggregate
   */
  type?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedrunsFeedRunAggregate
   */
  updatedAt?: string;
  /**
   *
   * @type {DataCoreSvcAuthActor}
   * @memberof DataCoreSvcFeedrunsFeedRunAggregate
   */
  updatedBy?: DataCoreSvcAuthActor;
  /**
   *
   * @type {Array<DataCoreSvcFeedrunsFeedRunUpdate>}
   * @memberof DataCoreSvcFeedrunsFeedRunAggregate
   */
  updates?: Array<DataCoreSvcFeedrunsFeedRunUpdate>;
}
/**
 *
 * @export
 * @interface DataCoreSvcFeedrunsFeedRunSummary
 */
export interface DataCoreSvcFeedrunsFeedRunSummary {
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedrunsFeedRunSummary
   */
  currentStep?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedrunsFeedRunSummary
   */
  duration?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedrunsFeedRunSummary
   */
  endTime?: string | null;
  /**
   *
   * @type {number}
   * @memberof DataCoreSvcFeedrunsFeedRunSummary
   */
  enumeratedItems?: number;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedrunsFeedRunSummary
   */
  error?: string | null;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedrunsFeedRunSummary
   */
  feedID?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedrunsFeedRunSummary
   */
  feedRunId?: string;
  /**
   *
   * @type {boolean}
   * @memberof DataCoreSvcFeedrunsFeedRunSummary
   */
  isFullyEnumerated?: boolean;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedrunsFeedRunSummary
   */
  message?: string;
  /**
   *
   * @type {number}
   * @memberof DataCoreSvcFeedrunsFeedRunSummary
   */
  processedItems?: number;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedrunsFeedRunSummary
   */
  startTime?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedrunsFeedRunSummary
   */
  status?: string;
}
/**
 *
 * @export
 * @interface DataCoreSvcFeedrunsFeedRunUpdate
 */
export interface DataCoreSvcFeedrunsFeedRunUpdate {
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedrunsFeedRunUpdate
   */
  endTime?: string | null;
  /**
   *
   * @type {boolean}
   * @memberof DataCoreSvcFeedrunsFeedRunUpdate
   */
  enumerationComplete?: boolean | null;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedrunsFeedRunUpdate
   */
  error?: string | null;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedrunsFeedRunUpdate
   */
  message?: string;
  /**
   *
   * @type {number}
   * @memberof DataCoreSvcFeedrunsFeedRunUpdate
   */
  newlyEnumeratedItems?: number | null;
  /**
   *
   * @type {number}
   * @memberof DataCoreSvcFeedrunsFeedRunUpdate
   */
  newlyProcessedItems?: number | null;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedrunsFeedRunUpdate
   */
  startTime?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedrunsFeedRunUpdate
   */
  status?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedrunsFeedRunUpdate
   */
  stepName?: string;
}
/**
 *
 * @export
 * @interface DataCoreSvcFeedsFeedAggregate
 */
export interface DataCoreSvcFeedsFeedAggregate {
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedsFeedAggregate
   */
  approvalStatus?: string;
  /**
   *
   * @type {{ [key: string]: any; }}
   * @memberof DataCoreSvcFeedsFeedAggregate
   */
  configuration?: { [key: string]: any } | null;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedsFeedAggregate
   */
  createdAt?: string;
  /**
   *
   * @type {DataCoreSvcAuthActor}
   * @memberof DataCoreSvcFeedsFeedAggregate
   */
  createdBy?: DataCoreSvcAuthActor;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedsFeedAggregate
   */
  dataSourceId?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedsFeedAggregate
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedsFeedAggregate
   */
  entityType?: string;
  /**
   *
   * @type {number}
   * @memberof DataCoreSvcFeedsFeedAggregate
   */
  expiresAtSEC?: number | null;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedsFeedAggregate
   */
  feature?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedsFeedAggregate
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedsFeedAggregate
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedsFeedAggregate
   */
  orgId?: string;
  /**
   *
   * @type {DataCoreSvcFeedsSchedule}
   * @memberof DataCoreSvcFeedsFeedAggregate
   */
  schedule?: DataCoreSvcFeedsSchedule;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedsFeedAggregate
   */
  status?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedsFeedAggregate
   */
  updatedAt?: string;
  /**
   *
   * @type {DataCoreSvcAuthActor}
   * @memberof DataCoreSvcFeedsFeedAggregate
   */
  updatedBy?: DataCoreSvcAuthActor;
}
/**
 *
 * @export
 * @interface DataCoreSvcFeedsFeedSummary
 */
export interface DataCoreSvcFeedsFeedSummary {
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedsFeedSummary
   */
  approvalStatus?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedsFeedSummary
   */
  createdAt?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedsFeedSummary
   */
  createdBy?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedsFeedSummary
   */
  cronExpression?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedsFeedSummary
   */
  dataSourceId?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedsFeedSummary
   */
  feature?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedsFeedSummary
   */
  feedId?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedsFeedSummary
   */
  lastFeedRunStatus?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedsFeedSummary
   */
  lastRunAt?: string | null;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedsFeedSummary
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedsFeedSummary
   */
  nextRunAt?: string | null;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedsFeedSummary
   */
  orgId?: string;
  /**
   *
   * @type {boolean}
   * @memberof DataCoreSvcFeedsFeedSummary
   */
  scheduleEnabled?: boolean;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedsFeedSummary
   */
  status?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedsFeedSummary
   */
  updatedAt?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedsFeedSummary
   */
  updatedBy?: string;
}
/**
 *
 * @export
 * @interface DataCoreSvcFeedsSchedule
 */
export interface DataCoreSvcFeedsSchedule {
  /**
   *
   * @type {number}
   * @memberof DataCoreSvcFeedsSchedule
   */
  allowSkewSec?: number;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedsSchedule
   */
  cronExpression?: string;
  /**
   *
   * @type {boolean}
   * @memberof DataCoreSvcFeedsSchedule
   */
  enabled?: boolean;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedsSchedule
   */
  lastRunAt?: string | null;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFeedsSchedule
   */
  nextRunAt?: string | null;
}
/**
 *
 * @export
 * @interface DataCoreSvcFilesvcFilePointer
 */
export interface DataCoreSvcFilesvcFilePointer {
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFilesvcFilePointer
   */
  bucket?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFilesvcFilePointer
   */
  fileId?: string;
}
/**
 *
 * @export
 * @interface DataCoreSvcFiltersArrayFieldFilter
 */
export interface DataCoreSvcFiltersArrayFieldFilter {
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFiltersArrayFieldFilter
   */
  beginsWith?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFiltersArrayFieldFilter
   */
  contains?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFiltersArrayFieldFilter
   */
  endsWith?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFiltersArrayFieldFilter
   */
  equals?: string;
  /**
   *
   * @type {DataCoreSvcDatafieldsDataFieldUnion}
   * @memberof DataCoreSvcFiltersArrayFieldFilter
   */
  field: DataCoreSvcDatafieldsDataFieldUnion;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFiltersArrayFieldFilter
   */
  notContains?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFiltersArrayFieldFilter
   */
  notEquals?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFiltersArrayFieldFilter
   */
  type: DataCoreSvcFiltersArrayFieldFilterTypeEnum;
}

export const DataCoreSvcFiltersArrayFieldFilterTypeEnum = {
  Array: 'array',
} as const;

export type DataCoreSvcFiltersArrayFieldFilterTypeEnum =
  typeof DataCoreSvcFiltersArrayFieldFilterTypeEnum[keyof typeof DataCoreSvcFiltersArrayFieldFilterTypeEnum];

/**
 *
 * @export
 * @interface DataCoreSvcFiltersBigNumberFilter
 */
export interface DataCoreSvcFiltersBigNumberFilter {
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFiltersBigNumberFilter
   */
  equals?: string | null;
  /**
   *
   * @type {DataCoreSvcDatafieldsDataFieldUnion}
   * @memberof DataCoreSvcFiltersBigNumberFilter
   */
  field: DataCoreSvcDatafieldsDataFieldUnion;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFiltersBigNumberFilter
   */
  maxExclusive?: string | null;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFiltersBigNumberFilter
   */
  maxInclusive?: string | null;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFiltersBigNumberFilter
   */
  minExclusive?: string | null;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFiltersBigNumberFilter
   */
  minInclusive?: string | null;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFiltersBigNumberFilter
   */
  notEquals?: string | null;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFiltersBigNumberFilter
   */
  type: DataCoreSvcFiltersBigNumberFilterTypeEnum;
}

export const DataCoreSvcFiltersBigNumberFilterTypeEnum = {
  BigNumber: 'bigNumber',
} as const;

export type DataCoreSvcFiltersBigNumberFilterTypeEnum =
  typeof DataCoreSvcFiltersBigNumberFilterTypeEnum[keyof typeof DataCoreSvcFiltersBigNumberFilterTypeEnum];

/**
 *
 * @export
 * @interface DataCoreSvcFiltersBooleanFilter
 */
export interface DataCoreSvcFiltersBooleanFilter {
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFiltersBooleanFilter
   */
  equals?: string;
  /**
   *
   * @type {DataCoreSvcDatafieldsDataFieldUnion}
   * @memberof DataCoreSvcFiltersBooleanFilter
   */
  field: DataCoreSvcDatafieldsDataFieldUnion;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFiltersBooleanFilter
   */
  type: DataCoreSvcFiltersBooleanFilterTypeEnum;
}

export const DataCoreSvcFiltersBooleanFilterTypeEnum = {
  Boolean: 'boolean',
} as const;

export type DataCoreSvcFiltersBooleanFilterTypeEnum =
  typeof DataCoreSvcFiltersBooleanFilterTypeEnum[keyof typeof DataCoreSvcFiltersBooleanFilterTypeEnum];

/**
 *
 * @export
 * @interface DataCoreSvcFiltersDateFilter
 */
export interface DataCoreSvcFiltersDateFilter {
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFiltersDateFilter
   */
  equals?: string | null;
  /**
   *
   * @type {DataCoreSvcDatafieldsDataFieldUnion}
   * @memberof DataCoreSvcFiltersDateFilter
   */
  field: DataCoreSvcDatafieldsDataFieldUnion;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFiltersDateFilter
   */
  maxExclusive?: string | null;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFiltersDateFilter
   */
  maxInclusive?: string | null;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFiltersDateFilter
   */
  minExclusive?: string | null;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFiltersDateFilter
   */
  minInclusive?: string | null;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFiltersDateFilter
   */
  notEquals?: string | null;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFiltersDateFilter
   */
  type: DataCoreSvcFiltersDateFilterTypeEnum;
}

export const DataCoreSvcFiltersDateFilterTypeEnum = {
  Date: 'date',
} as const;

export type DataCoreSvcFiltersDateFilterTypeEnum =
  typeof DataCoreSvcFiltersDateFilterTypeEnum[keyof typeof DataCoreSvcFiltersDateFilterTypeEnum];

/**
 *
 * @export
 * @interface DataCoreSvcFiltersDateTimeFilter
 */
export interface DataCoreSvcFiltersDateTimeFilter {
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFiltersDateTimeFilter
   */
  equals?: string | null;
  /**
   *
   * @type {DataCoreSvcDatafieldsDataFieldUnion}
   * @memberof DataCoreSvcFiltersDateTimeFilter
   */
  field: DataCoreSvcDatafieldsDataFieldUnion;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFiltersDateTimeFilter
   */
  maxExclusive?: string | null;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFiltersDateTimeFilter
   */
  maxInclusive?: string | null;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFiltersDateTimeFilter
   */
  minExclusive?: string | null;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFiltersDateTimeFilter
   */
  minInclusive?: string | null;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFiltersDateTimeFilter
   */
  notEquals?: string | null;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFiltersDateTimeFilter
   */
  type: DataCoreSvcFiltersDateTimeFilterTypeEnum;
}

export const DataCoreSvcFiltersDateTimeFilterTypeEnum = {
  Datetime: 'datetime',
} as const;

export type DataCoreSvcFiltersDateTimeFilterTypeEnum =
  typeof DataCoreSvcFiltersDateTimeFilterTypeEnum[keyof typeof DataCoreSvcFiltersDateTimeFilterTypeEnum];

/**
 *
 * @export
 * @interface DataCoreSvcFiltersEnumFilter
 */
export interface DataCoreSvcFiltersEnumFilter {
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFiltersEnumFilter
   */
  beginsWith?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFiltersEnumFilter
   */
  contains?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFiltersEnumFilter
   */
  endsWith?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFiltersEnumFilter
   */
  equals?: string;
  /**
   *
   * @type {DataCoreSvcDatafieldsDataFieldUnion}
   * @memberof DataCoreSvcFiltersEnumFilter
   */
  field: DataCoreSvcDatafieldsDataFieldUnion;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFiltersEnumFilter
   */
  notContains?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFiltersEnumFilter
   */
  notEquals?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFiltersEnumFilter
   */
  type: DataCoreSvcFiltersEnumFilterTypeEnum;
}

export const DataCoreSvcFiltersEnumFilterTypeEnum = {
  Enum: 'enum',
} as const;

export type DataCoreSvcFiltersEnumFilterTypeEnum =
  typeof DataCoreSvcFiltersEnumFilterTypeEnum[keyof typeof DataCoreSvcFiltersEnumFilterTypeEnum];

/**
 * @type DataCoreSvcFiltersFilterUnion
 * @export
 */
export type DataCoreSvcFiltersFilterUnion =
  | DataCoreSvcFiltersArrayFieldFilter
  | DataCoreSvcFiltersBigNumberFilter
  | DataCoreSvcFiltersBooleanFilter
  | DataCoreSvcFiltersDateFilter
  | DataCoreSvcFiltersDateTimeFilter
  | DataCoreSvcFiltersEnumFilter
  | DataCoreSvcFiltersNumberFilter
  | DataCoreSvcFiltersObjectFilter
  | DataCoreSvcFiltersRangeFilter
  | DataCoreSvcFiltersStringFilter
  | DataCoreSvcFiltersTickerFilter
  | DataCoreSvcFiltersTimestampFilter
  | DataCoreSvcFiltersUndefinedFilter;

/**
 *
 * @export
 * @interface DataCoreSvcFiltersNumberFilter
 */
export interface DataCoreSvcFiltersNumberFilter {
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFiltersNumberFilter
   */
  equals?: string | null;
  /**
   *
   * @type {DataCoreSvcDatafieldsDataFieldUnion}
   * @memberof DataCoreSvcFiltersNumberFilter
   */
  field: DataCoreSvcDatafieldsDataFieldUnion;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFiltersNumberFilter
   */
  maxExclusive?: string | null;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFiltersNumberFilter
   */
  maxInclusive?: string | null;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFiltersNumberFilter
   */
  minExclusive?: string | null;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFiltersNumberFilter
   */
  minInclusive?: string | null;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFiltersNumberFilter
   */
  notEquals?: string | null;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFiltersNumberFilter
   */
  type: DataCoreSvcFiltersNumberFilterTypeEnum;
}

export const DataCoreSvcFiltersNumberFilterTypeEnum = {
  Number: 'number',
} as const;

export type DataCoreSvcFiltersNumberFilterTypeEnum =
  typeof DataCoreSvcFiltersNumberFilterTypeEnum[keyof typeof DataCoreSvcFiltersNumberFilterTypeEnum];

/**
 *
 * @export
 * @interface DataCoreSvcFiltersObjectFilter
 */
export interface DataCoreSvcFiltersObjectFilter {
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFiltersObjectFilter
   */
  beginsWith?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFiltersObjectFilter
   */
  contains?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFiltersObjectFilter
   */
  endsWith?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFiltersObjectFilter
   */
  equals?: string;
  /**
   *
   * @type {DataCoreSvcDatafieldsDataFieldUnion}
   * @memberof DataCoreSvcFiltersObjectFilter
   */
  field: DataCoreSvcDatafieldsDataFieldUnion;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFiltersObjectFilter
   */
  notContains?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFiltersObjectFilter
   */
  notEquals?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFiltersObjectFilter
   */
  type: DataCoreSvcFiltersObjectFilterTypeEnum;
}

export const DataCoreSvcFiltersObjectFilterTypeEnum = {
  Object: 'object',
} as const;

export type DataCoreSvcFiltersObjectFilterTypeEnum =
  typeof DataCoreSvcFiltersObjectFilterTypeEnum[keyof typeof DataCoreSvcFiltersObjectFilterTypeEnum];

/**
 *
 * @export
 * @interface DataCoreSvcFiltersRangeFilter
 */
export interface DataCoreSvcFiltersRangeFilter {
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFiltersRangeFilter
   */
  beginsWith?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFiltersRangeFilter
   */
  contains?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFiltersRangeFilter
   */
  endsWith?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFiltersRangeFilter
   */
  equals?: string;
  /**
   *
   * @type {DataCoreSvcDatafieldsDataFieldUnion}
   * @memberof DataCoreSvcFiltersRangeFilter
   */
  field: DataCoreSvcDatafieldsDataFieldUnion;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFiltersRangeFilter
   */
  notContains?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFiltersRangeFilter
   */
  notEquals?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFiltersRangeFilter
   */
  type: DataCoreSvcFiltersRangeFilterTypeEnum;
}

export const DataCoreSvcFiltersRangeFilterTypeEnum = {
  Range: 'range',
} as const;

export type DataCoreSvcFiltersRangeFilterTypeEnum =
  typeof DataCoreSvcFiltersRangeFilterTypeEnum[keyof typeof DataCoreSvcFiltersRangeFilterTypeEnum];

/**
 *
 * @export
 * @interface DataCoreSvcFiltersStringFilter
 */
export interface DataCoreSvcFiltersStringFilter {
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFiltersStringFilter
   */
  beginsWith?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFiltersStringFilter
   */
  contains?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFiltersStringFilter
   */
  endsWith?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFiltersStringFilter
   */
  equals?: string;
  /**
   *
   * @type {DataCoreSvcDatafieldsDataFieldUnion}
   * @memberof DataCoreSvcFiltersStringFilter
   */
  field: DataCoreSvcDatafieldsDataFieldUnion;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFiltersStringFilter
   */
  notContains?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFiltersStringFilter
   */
  notEquals?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFiltersStringFilter
   */
  type: DataCoreSvcFiltersStringFilterTypeEnum;
}

export const DataCoreSvcFiltersStringFilterTypeEnum = {
  StringType: 'stringType',
} as const;

export type DataCoreSvcFiltersStringFilterTypeEnum =
  typeof DataCoreSvcFiltersStringFilterTypeEnum[keyof typeof DataCoreSvcFiltersStringFilterTypeEnum];

/**
 *
 * @export
 * @interface DataCoreSvcFiltersTickerFilter
 */
export interface DataCoreSvcFiltersTickerFilter {
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFiltersTickerFilter
   */
  beginsWith?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFiltersTickerFilter
   */
  contains?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFiltersTickerFilter
   */
  endsWith?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFiltersTickerFilter
   */
  equals?: string;
  /**
   *
   * @type {DataCoreSvcDatafieldsDataFieldUnion}
   * @memberof DataCoreSvcFiltersTickerFilter
   */
  field: DataCoreSvcDatafieldsDataFieldUnion;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFiltersTickerFilter
   */
  notContains?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFiltersTickerFilter
   */
  notEquals?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFiltersTickerFilter
   */
  type: DataCoreSvcFiltersTickerFilterTypeEnum;
}

export const DataCoreSvcFiltersTickerFilterTypeEnum = {
  Ticker: 'ticker',
} as const;

export type DataCoreSvcFiltersTickerFilterTypeEnum =
  typeof DataCoreSvcFiltersTickerFilterTypeEnum[keyof typeof DataCoreSvcFiltersTickerFilterTypeEnum];

/**
 *
 * @export
 * @interface DataCoreSvcFiltersTimestampFilter
 */
export interface DataCoreSvcFiltersTimestampFilter {
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFiltersTimestampFilter
   */
  equals?: string | null;
  /**
   *
   * @type {DataCoreSvcDatafieldsDataFieldUnion}
   * @memberof DataCoreSvcFiltersTimestampFilter
   */
  field: DataCoreSvcDatafieldsDataFieldUnion;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFiltersTimestampFilter
   */
  maxExclusive?: string | null;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFiltersTimestampFilter
   */
  maxInclusive?: string | null;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFiltersTimestampFilter
   */
  minExclusive?: string | null;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFiltersTimestampFilter
   */
  minInclusive?: string | null;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFiltersTimestampFilter
   */
  notEquals?: string | null;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFiltersTimestampFilter
   */
  type: DataCoreSvcFiltersTimestampFilterTypeEnum;
}

export const DataCoreSvcFiltersTimestampFilterTypeEnum = {
  Timestamp: 'timestamp',
} as const;

export type DataCoreSvcFiltersTimestampFilterTypeEnum =
  typeof DataCoreSvcFiltersTimestampFilterTypeEnum[keyof typeof DataCoreSvcFiltersTimestampFilterTypeEnum];

/**
 *
 * @export
 * @interface DataCoreSvcFiltersUndefinedFilter
 */
export interface DataCoreSvcFiltersUndefinedFilter {
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFiltersUndefinedFilter
   */
  exact?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcFiltersUndefinedFilter
   */
  type: DataCoreSvcFiltersUndefinedFilterTypeEnum;
}

export const DataCoreSvcFiltersUndefinedFilterTypeEnum = {
  UndefinedType: 'undefinedType',
} as const;

export type DataCoreSvcFiltersUndefinedFilterTypeEnum =
  typeof DataCoreSvcFiltersUndefinedFilterTypeEnum[keyof typeof DataCoreSvcFiltersUndefinedFilterTypeEnum];

/**
 *
 * @export
 * @interface DataCoreSvcImporthdlGetOutput
 */
export interface DataCoreSvcImporthdlGetOutput {
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcImporthdlGetOutput
   */
  adaptedDataSourceId?: string | null;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcImporthdlGetOutput
   */
  bucket?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcImporthdlGetOutput
   */
  completedAt?: string | null;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcImporthdlGetOutput
   */
  createdAt?: string;
  /**
   *
   * @type {DataCoreSvcAuthActor}
   * @memberof DataCoreSvcImporthdlGetOutput
   */
  createdBy?: DataCoreSvcAuthActor;
  /**
   *
   * @type {Array<string>}
   * @memberof DataCoreSvcImporthdlGetOutput
   */
  errors?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcImporthdlGetOutput
   */
  feedId?: string | null;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcImporthdlGetOutput
   */
  feedRunId?: string | null;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcImporthdlGetOutput
   */
  fileId?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcImporthdlGetOutput
   */
  importId?: string;
  /**
   *
   * @type {boolean}
   * @memberof DataCoreSvcImporthdlGetOutput
   */
  jaggedRows?: boolean;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcImporthdlGetOutput
   */
  lastFeedRunStatus?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof DataCoreSvcImporthdlGetOutput
   */
  messages?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcImporthdlGetOutput
   */
  orgId?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcImporthdlGetOutput
   */
  range?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcImporthdlGetOutput
   */
  rawDataSourceId?: string | null;
  /**
   *
   * @type {number}
   * @memberof DataCoreSvcImporthdlGetOutput
   */
  skipLeadingRows?: number;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcImporthdlGetOutput
   */
  status?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcImporthdlGetOutput
   */
  updatedAt?: string;
  /**
   *
   * @type {DataCoreSvcAuthActor}
   * @memberof DataCoreSvcImporthdlGetOutput
   */
  updatedBy?: DataCoreSvcAuthActor;
  /**
   *
   * @type {Array<string>}
   * @memberof DataCoreSvcImporthdlGetOutput
   */
  validationErrors?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof DataCoreSvcImporthdlGetOutput
   */
  warnings?: Array<string>;
}
/**
 *
 * @export
 * @interface DataCoreSvcImportsImportSummary
 */
export interface DataCoreSvcImportsImportSummary {
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcImportsImportSummary
   */
  adaptedDataSourceId?: string | null;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcImportsImportSummary
   */
  bucket?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcImportsImportSummary
   */
  completedAt?: string | null;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcImportsImportSummary
   */
  createdAt?: string;
  /**
   *
   * @type {DataCoreSvcAuthActor}
   * @memberof DataCoreSvcImportsImportSummary
   */
  createdBy?: DataCoreSvcAuthActor;
  /**
   *
   * @type {Array<string>}
   * @memberof DataCoreSvcImportsImportSummary
   */
  errors?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcImportsImportSummary
   */
  feedId?: string | null;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcImportsImportSummary
   */
  feedRunId?: string | null;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcImportsImportSummary
   */
  fileId?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcImportsImportSummary
   */
  importId?: string;
  /**
   *
   * @type {boolean}
   * @memberof DataCoreSvcImportsImportSummary
   */
  jaggedRows?: boolean;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcImportsImportSummary
   */
  lastFeedRunStatus?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof DataCoreSvcImportsImportSummary
   */
  messages?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcImportsImportSummary
   */
  orgId?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcImportsImportSummary
   */
  range?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcImportsImportSummary
   */
  rawDataSourceId?: string | null;
  /**
   *
   * @type {number}
   * @memberof DataCoreSvcImportsImportSummary
   */
  skipLeadingRows?: number;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcImportsImportSummary
   */
  status?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcImportsImportSummary
   */
  updatedAt?: string;
  /**
   *
   * @type {DataCoreSvcAuthActor}
   * @memberof DataCoreSvcImportsImportSummary
   */
  updatedBy?: DataCoreSvcAuthActor;
  /**
   *
   * @type {Array<string>}
   * @memberof DataCoreSvcImportsImportSummary
   */
  validationErrors?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof DataCoreSvcImportsImportSummary
   */
  warnings?: Array<string>;
}
/**
 *
 * @export
 * @interface DataCoreSvcOrderbysOrderBy
 */
export interface DataCoreSvcOrderbysOrderBy {
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcOrderbysOrderBy
   */
  direction: string;
  /**
   *
   * @type {DataCoreSvcDatafieldsDataFieldUnion}
   * @memberof DataCoreSvcOrderbysOrderBy
   */
  field: DataCoreSvcDatafieldsDataFieldUnion;
}
/**
 *
 * @export
 * @interface DataCoreSvcPortsCsvLinkResponse
 */
export interface DataCoreSvcPortsCsvLinkResponse {
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcPortsCsvLinkResponse
   */
  downloadUrl?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcPortsCsvLinkResponse
   */
  importId?: string;
}
/**
 *
 * @export
 * @interface DataCoreSvcPortsGetSyncImportResponse
 */
export interface DataCoreSvcPortsGetSyncImportResponse {
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcPortsGetSyncImportResponse
   */
  importId?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof DataCoreSvcPortsGetSyncImportResponse
   */
  messages?: Array<string> | null;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcPortsGetSyncImportResponse
   */
  status?: string;
  /**
   *
   * @type {DataCoreSvcFeedrunsFeedRun}
   * @memberof DataCoreSvcPortsGetSyncImportResponse
   */
  syncImport?: DataCoreSvcFeedrunsFeedRun;
  /**
   *
   * @type {Array<string>}
   * @memberof DataCoreSvcPortsGetSyncImportResponse
   */
  warnings?: Array<string> | null;
}
/**
 *
 * @export
 * @interface DataCoreSvcPortsInitImportResponse
 */
export interface DataCoreSvcPortsInitImportResponse {
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcPortsInitImportResponse
   */
  importId?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof DataCoreSvcPortsInitImportResponse
   */
  messages?: Array<string> | null;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcPortsInitImportResponse
   */
  status?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcPortsInitImportResponse
   */
  uploadUrl?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof DataCoreSvcPortsInitImportResponse
   */
  warnings?: Array<string> | null;
}
/**
 *
 * @export
 * @interface DataCoreSvcPortsListImportsResponse
 */
export interface DataCoreSvcPortsListImportsResponse {
  /**
   *
   * @type {Array<DataCoreSvcImportsImportSummary>}
   * @memberof DataCoreSvcPortsListImportsResponse
   */
  items?: Array<DataCoreSvcImportsImportSummary> | null;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcPortsListImportsResponse
   */
  nextPageToken?: string;
}
/**
 *
 * @export
 * @interface DataCoreSvcPortsPostSyncImportResponse
 */
export interface DataCoreSvcPortsPostSyncImportResponse {
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcPortsPostSyncImportResponse
   */
  feedRunId?: string;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcPortsPostSyncImportResponse
   */
  importId?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof DataCoreSvcPortsPostSyncImportResponse
   */
  messages?: Array<string> | null;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcPortsPostSyncImportResponse
   */
  status?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof DataCoreSvcPortsPostSyncImportResponse
   */
  warnings?: Array<string> | null;
}
/**
 *
 * @export
 * @interface DataCoreSvcPortsPreviewImportResponse
 */
export interface DataCoreSvcPortsPreviewImportResponse {
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcPortsPreviewImportResponse
   */
  importId?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof DataCoreSvcPortsPreviewImportResponse
   */
  messages?: Array<string> | null;
  /**
   *
   * @type {any}
   * @memberof DataCoreSvcPortsPreviewImportResponse
   */
  previewImport?: any;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcPortsPreviewImportResponse
   */
  status?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof DataCoreSvcPortsPreviewImportResponse
   */
  warnings?: Array<string> | null;
}
/**
 *
 * @export
 * @interface DataCoreSvcPortsValidateImportResponse
 */
export interface DataCoreSvcPortsValidateImportResponse {
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcPortsValidateImportResponse
   */
  adaptedDataSourceId?: string;
  /**
   *
   * @type {number}
   * @memberof DataCoreSvcPortsValidateImportResponse
   */
  adaptedItems?: number;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcPortsValidateImportResponse
   */
  importId?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof DataCoreSvcPortsValidateImportResponse
   */
  messages?: Array<string> | null;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcPortsValidateImportResponse
   */
  rawDataSourceId?: string;
  /**
   *
   * @type {number}
   * @memberof DataCoreSvcPortsValidateImportResponse
   */
  rawItems?: number;
  /**
   *
   * @type {string}
   * @memberof DataCoreSvcPortsValidateImportResponse
   */
  status?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof DataCoreSvcPortsValidateImportResponse
   */
  warnings?: Array<string> | null;
}
/**
 *
 * @export
 * @interface DataCoreSvcRestErrResponse
 */
export interface DataCoreSvcRestErrResponse {
  /**
   * Application-specific error code.
   * @type {number}
   * @memberof DataCoreSvcRestErrResponse
   */
  code?: number;
  /**
   * Application context.
   * @type {{ [key: string]: any; }}
   * @memberof DataCoreSvcRestErrResponse
   */
  context?: { [key: string]: any };
  /**
   * Error message.
   * @type {string}
   * @memberof DataCoreSvcRestErrResponse
   */
  error?: string;
  /**
   * Status text.
   * @type {string}
   * @memberof DataCoreSvcRestErrResponse
   */
  status?: string;
}

/**
 * DataSourcesApi - axios parameter creator
 * @export
 */
export const DataSourcesApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Creates a new data source
     * @summary Create DataSource
     * @param {string} orgId
     * @param {DataCoreSvcDatasourcehdlCreateInput} [dataCoreSvcDatasourcehdlCreateInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersDatasourcehdlDataSourceHTTPHandlerCreate: async (
      orgId: string,
      dataCoreSvcDatasourcehdlCreateInput?: DataCoreSvcDatasourcehdlCreateInput,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('handlersDatasourcehdlDataSourceHTTPHandlerCreate', 'orgId', orgId);
      const localVarPath = `/v3/orgs/{orgId}/data-sources/`.replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        dataCoreSvcDatasourcehdlCreateInput,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Creates a new data source
     * @summary Create DataSource
     * @param {string} orgId
     * @param {DataCoreSvcDatasourcehdlCreateInput} [dataCoreSvcDatasourcehdlCreateInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersDatasourcehdlDataSourceHTTPHandlerCreate2: async (
      orgId: string,
      dataCoreSvcDatasourcehdlCreateInput?: DataCoreSvcDatasourcehdlCreateInput,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('handlersDatasourcehdlDataSourceHTTPHandlerCreate2', 'orgId', orgId);
      const localVarPath = `/orgs/{orgId}/data-sources/`.replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        dataCoreSvcDatasourcehdlCreateInput,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Exports data source content to a downloadable file
     * @summary Export DataSource
     * @param {string} orgId
     * @param {string} dataSourceId
     * @param {string} [pageToken]
     * @param {string} [bqPageToken]
     * @param {number} [pageSize]
     * @param {number} [pageNumber]
     * @param {DataCoreSvcDatasourcehdlRowsInput} [dataCoreSvcDatasourcehdlRowsInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersDatasourcehdlDataSourceHTTPHandlerExport: async (
      orgId: string,
      dataSourceId: string,
      pageToken?: string,
      bqPageToken?: string,
      pageSize?: number,
      pageNumber?: number,
      dataCoreSvcDatasourcehdlRowsInput?: DataCoreSvcDatasourcehdlRowsInput,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('handlersDatasourcehdlDataSourceHTTPHandlerExport', 'orgId', orgId);
      // verify required parameter 'dataSourceId' is not null or undefined
      assertParamExists('handlersDatasourcehdlDataSourceHTTPHandlerExport', 'dataSourceId', dataSourceId);
      const localVarPath = `/v3/orgs/{orgId}/data-sources/{dataSourceId}/export`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'dataSourceId'}}`, encodeURIComponent(String(dataSourceId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (pageToken !== undefined) {
        localVarQueryParameter['pageToken'] = pageToken;
      }

      if (bqPageToken !== undefined) {
        localVarQueryParameter['bqPageToken'] = bqPageToken;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['pageSize'] = pageSize;
      }

      if (pageNumber !== undefined) {
        localVarQueryParameter['pageNumber'] = pageNumber;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        dataCoreSvcDatasourcehdlRowsInput,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Exports data source content to a downloadable file
     * @summary Export DataSource
     * @param {string} orgId
     * @param {string} dataSourceId
     * @param {string} [pageToken]
     * @param {string} [bqPageToken]
     * @param {number} [pageSize]
     * @param {number} [pageNumber]
     * @param {DataCoreSvcDatasourcehdlRowsInput} [dataCoreSvcDatasourcehdlRowsInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersDatasourcehdlDataSourceHTTPHandlerExport2: async (
      orgId: string,
      dataSourceId: string,
      pageToken?: string,
      bqPageToken?: string,
      pageSize?: number,
      pageNumber?: number,
      dataCoreSvcDatasourcehdlRowsInput?: DataCoreSvcDatasourcehdlRowsInput,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('handlersDatasourcehdlDataSourceHTTPHandlerExport2', 'orgId', orgId);
      // verify required parameter 'dataSourceId' is not null or undefined
      assertParamExists('handlersDatasourcehdlDataSourceHTTPHandlerExport2', 'dataSourceId', dataSourceId);
      const localVarPath = `/orgs/{orgId}/data-sources/{dataSourceId}/export`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'dataSourceId'}}`, encodeURIComponent(String(dataSourceId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (pageToken !== undefined) {
        localVarQueryParameter['pageToken'] = pageToken;
      }

      if (bqPageToken !== undefined) {
        localVarQueryParameter['bqPageToken'] = bqPageToken;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['pageSize'] = pageSize;
      }

      if (pageNumber !== undefined) {
        localVarQueryParameter['pageNumber'] = pageNumber;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        dataCoreSvcDatasourcehdlRowsInput,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns a signed URL for uploading a CSV file and a file pointer for subsequent operations
     * @summary Get CSV Upload URL
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersDatasourcehdlDataSourceHTTPHandlerGetCsvUploadUrl: async (
      orgId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('handlersDatasourcehdlDataSourceHTTPHandlerGetCsvUploadUrl', 'orgId', orgId);
      const localVarPath = `/v3/orgs/{orgId}/data-sources/csv-upload-url`.replace(
        `{${'orgId'}}`,
        encodeURIComponent(String(orgId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Returns a signed URL for uploading a CSV file and a file pointer for subsequent operations
     * @summary Get CSV Upload URL
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersDatasourcehdlDataSourceHTTPHandlerGetCsvUploadUrl2: async (
      orgId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('handlersDatasourcehdlDataSourceHTTPHandlerGetCsvUploadUrl2', 'orgId', orgId);
      const localVarPath = `/orgs/{orgId}/data-sources/csv-upload-url`.replace(
        `{${'orgId'}}`,
        encodeURIComponent(String(orgId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Gets rows from a data source with optional filtering and transformations
     * @summary Get DataSource Rows
     * @param {string} orgId
     * @param {string} dataSourceId
     * @param {string} [pageToken]
     * @param {string} [bqPageToken]
     * @param {number} [pageSize]
     * @param {number} [pageNumber]
     * @param {DataCoreSvcDatasourcehdlRowsInput} [dataCoreSvcDatasourcehdlRowsInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersDatasourcehdlDataSourceHTTPHandlerRows: async (
      orgId: string,
      dataSourceId: string,
      pageToken?: string,
      bqPageToken?: string,
      pageSize?: number,
      pageNumber?: number,
      dataCoreSvcDatasourcehdlRowsInput?: DataCoreSvcDatasourcehdlRowsInput,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('handlersDatasourcehdlDataSourceHTTPHandlerRows', 'orgId', orgId);
      // verify required parameter 'dataSourceId' is not null or undefined
      assertParamExists('handlersDatasourcehdlDataSourceHTTPHandlerRows', 'dataSourceId', dataSourceId);
      const localVarPath = `/v3/orgs/{orgId}/data-sources/{dataSourceId}/rows`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'dataSourceId'}}`, encodeURIComponent(String(dataSourceId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (pageToken !== undefined) {
        localVarQueryParameter['pageToken'] = pageToken;
      }

      if (bqPageToken !== undefined) {
        localVarQueryParameter['bqPageToken'] = bqPageToken;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['pageSize'] = pageSize;
      }

      if (pageNumber !== undefined) {
        localVarQueryParameter['pageNumber'] = pageNumber;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        dataCoreSvcDatasourcehdlRowsInput,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Gets rows from a data source with optional filtering and transformations
     * @summary Get DataSource Rows
     * @param {string} orgId
     * @param {string} dataSourceId
     * @param {string} [pageToken]
     * @param {string} [bqPageToken]
     * @param {number} [pageSize]
     * @param {number} [pageNumber]
     * @param {DataCoreSvcDatasourcehdlRowsInput} [dataCoreSvcDatasourcehdlRowsInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersDatasourcehdlDataSourceHTTPHandlerRows2: async (
      orgId: string,
      dataSourceId: string,
      pageToken?: string,
      bqPageToken?: string,
      pageSize?: number,
      pageNumber?: number,
      dataCoreSvcDatasourcehdlRowsInput?: DataCoreSvcDatasourcehdlRowsInput,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('handlersDatasourcehdlDataSourceHTTPHandlerRows2', 'orgId', orgId);
      // verify required parameter 'dataSourceId' is not null or undefined
      assertParamExists('handlersDatasourcehdlDataSourceHTTPHandlerRows2', 'dataSourceId', dataSourceId);
      const localVarPath = `/orgs/{orgId}/data-sources/{dataSourceId}/rows`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'dataSourceId'}}`, encodeURIComponent(String(dataSourceId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (pageToken !== undefined) {
        localVarQueryParameter['pageToken'] = pageToken;
      }

      if (bqPageToken !== undefined) {
        localVarQueryParameter['bqPageToken'] = bqPageToken;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['pageSize'] = pageSize;
      }

      if (pageNumber !== undefined) {
        localVarQueryParameter['pageNumber'] = pageNumber;
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        dataCoreSvcDatasourcehdlRowsInput,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Gets the schema definition for a data source
     * @summary Get DataSource Schema
     * @param {string} orgId
     * @param {string} dataSourceId
     * @param {string} [pageToken]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersDatasourcehdlDataSourceHTTPHandlerSchema: async (
      orgId: string,
      dataSourceId: string,
      pageToken?: string,
      pageSize?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('handlersDatasourcehdlDataSourceHTTPHandlerSchema', 'orgId', orgId);
      // verify required parameter 'dataSourceId' is not null or undefined
      assertParamExists('handlersDatasourcehdlDataSourceHTTPHandlerSchema', 'dataSourceId', dataSourceId);
      const localVarPath = `/v3/orgs/{orgId}/data-sources/{dataSourceId}/schema`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'dataSourceId'}}`, encodeURIComponent(String(dataSourceId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (pageToken !== undefined) {
        localVarQueryParameter['pageToken'] = pageToken;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['pageSize'] = pageSize;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Gets the schema definition for a data source
     * @summary Get DataSource Schema
     * @param {string} orgId
     * @param {string} dataSourceId
     * @param {string} [pageToken]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersDatasourcehdlDataSourceHTTPHandlerSchema2: async (
      orgId: string,
      dataSourceId: string,
      pageToken?: string,
      pageSize?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('handlersDatasourcehdlDataSourceHTTPHandlerSchema2', 'orgId', orgId);
      // verify required parameter 'dataSourceId' is not null or undefined
      assertParamExists('handlersDatasourcehdlDataSourceHTTPHandlerSchema2', 'dataSourceId', dataSourceId);
      const localVarPath = `/orgs/{orgId}/data-sources/{dataSourceId}/schema`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'dataSourceId'}}`, encodeURIComponent(String(dataSourceId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (pageToken !== undefined) {
        localVarQueryParameter['pageToken'] = pageToken;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['pageSize'] = pageSize;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Validates a data source configuration and connection
     * @summary Validate DataSource
     * @param {string} orgId
     * @param {string} dataSourceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersDatasourcehdlDataSourceHTTPHandlerValidate: async (
      orgId: string,
      dataSourceId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('handlersDatasourcehdlDataSourceHTTPHandlerValidate', 'orgId', orgId);
      // verify required parameter 'dataSourceId' is not null or undefined
      assertParamExists('handlersDatasourcehdlDataSourceHTTPHandlerValidate', 'dataSourceId', dataSourceId);
      const localVarPath = `/v3/orgs/{orgId}/data-sources/{dataSourceId}/validator`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'dataSourceId'}}`, encodeURIComponent(String(dataSourceId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Validates a data source configuration and connection
     * @summary Validate DataSource
     * @param {string} orgId
     * @param {string} dataSourceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersDatasourcehdlDataSourceHTTPHandlerValidate2: async (
      orgId: string,
      dataSourceId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('handlersDatasourcehdlDataSourceHTTPHandlerValidate2', 'orgId', orgId);
      // verify required parameter 'dataSourceId' is not null or undefined
      assertParamExists('handlersDatasourcehdlDataSourceHTTPHandlerValidate2', 'dataSourceId', dataSourceId);
      const localVarPath = `/orgs/{orgId}/data-sources/{dataSourceId}/validator`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'dataSourceId'}}`, encodeURIComponent(String(dataSourceId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DataSourcesApi - functional programming interface
 * @export
 */
export const DataSourcesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DataSourcesApiAxiosParamCreator(configuration);
  return {
    /**
     * Creates a new data source
     * @summary Create DataSource
     * @param {string} orgId
     * @param {DataCoreSvcDatasourcehdlCreateInput} [dataCoreSvcDatasourcehdlCreateInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async handlersDatasourcehdlDataSourceHTTPHandlerCreate(
      orgId: string,
      dataCoreSvcDatasourcehdlCreateInput?: DataCoreSvcDatasourcehdlCreateInput,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataCoreSvcDatasourcesDataSourcePointer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.handlersDatasourcehdlDataSourceHTTPHandlerCreate(
        orgId,
        dataCoreSvcDatasourcehdlCreateInput,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Creates a new data source
     * @summary Create DataSource
     * @param {string} orgId
     * @param {DataCoreSvcDatasourcehdlCreateInput} [dataCoreSvcDatasourcehdlCreateInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async handlersDatasourcehdlDataSourceHTTPHandlerCreate2(
      orgId: string,
      dataCoreSvcDatasourcehdlCreateInput?: DataCoreSvcDatasourcehdlCreateInput,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataCoreSvcDatasourcesDataSourcePointer>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.handlersDatasourcehdlDataSourceHTTPHandlerCreate2(
        orgId,
        dataCoreSvcDatasourcehdlCreateInput,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Exports data source content to a downloadable file
     * @summary Export DataSource
     * @param {string} orgId
     * @param {string} dataSourceId
     * @param {string} [pageToken]
     * @param {string} [bqPageToken]
     * @param {number} [pageSize]
     * @param {number} [pageNumber]
     * @param {DataCoreSvcDatasourcehdlRowsInput} [dataCoreSvcDatasourcehdlRowsInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async handlersDatasourcehdlDataSourceHTTPHandlerExport(
      orgId: string,
      dataSourceId: string,
      pageToken?: string,
      bqPageToken?: string,
      pageSize?: number,
      pageNumber?: number,
      dataCoreSvcDatasourcehdlRowsInput?: DataCoreSvcDatasourcehdlRowsInput,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataCoreSvcDatasourcehdlDownloadOutput>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.handlersDatasourcehdlDataSourceHTTPHandlerExport(
        orgId,
        dataSourceId,
        pageToken,
        bqPageToken,
        pageSize,
        pageNumber,
        dataCoreSvcDatasourcehdlRowsInput,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Exports data source content to a downloadable file
     * @summary Export DataSource
     * @param {string} orgId
     * @param {string} dataSourceId
     * @param {string} [pageToken]
     * @param {string} [bqPageToken]
     * @param {number} [pageSize]
     * @param {number} [pageNumber]
     * @param {DataCoreSvcDatasourcehdlRowsInput} [dataCoreSvcDatasourcehdlRowsInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async handlersDatasourcehdlDataSourceHTTPHandlerExport2(
      orgId: string,
      dataSourceId: string,
      pageToken?: string,
      bqPageToken?: string,
      pageSize?: number,
      pageNumber?: number,
      dataCoreSvcDatasourcehdlRowsInput?: DataCoreSvcDatasourcehdlRowsInput,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataCoreSvcDatasourcehdlDownloadOutput>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.handlersDatasourcehdlDataSourceHTTPHandlerExport2(
        orgId,
        dataSourceId,
        pageToken,
        bqPageToken,
        pageSize,
        pageNumber,
        dataCoreSvcDatasourcehdlRowsInput,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Returns a signed URL for uploading a CSV file and a file pointer for subsequent operations
     * @summary Get CSV Upload URL
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async handlersDatasourcehdlDataSourceHTTPHandlerGetCsvUploadUrl(
      orgId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataCoreSvcDatasourcehdlCsvUploadUrlOutput>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.handlersDatasourcehdlDataSourceHTTPHandlerGetCsvUploadUrl(orgId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Returns a signed URL for uploading a CSV file and a file pointer for subsequent operations
     * @summary Get CSV Upload URL
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async handlersDatasourcehdlDataSourceHTTPHandlerGetCsvUploadUrl2(
      orgId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataCoreSvcDatasourcehdlCsvUploadUrlOutput>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.handlersDatasourcehdlDataSourceHTTPHandlerGetCsvUploadUrl2(orgId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Gets rows from a data source with optional filtering and transformations
     * @summary Get DataSource Rows
     * @param {string} orgId
     * @param {string} dataSourceId
     * @param {string} [pageToken]
     * @param {string} [bqPageToken]
     * @param {number} [pageSize]
     * @param {number} [pageNumber]
     * @param {DataCoreSvcDatasourcehdlRowsInput} [dataCoreSvcDatasourcehdlRowsInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async handlersDatasourcehdlDataSourceHTTPHandlerRows(
      orgId: string,
      dataSourceId: string,
      pageToken?: string,
      bqPageToken?: string,
      pageSize?: number,
      pageNumber?: number,
      dataCoreSvcDatasourcehdlRowsInput?: DataCoreSvcDatasourcehdlRowsInput,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataCoreSvcDatasourcehdlRowsOutput>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.handlersDatasourcehdlDataSourceHTTPHandlerRows(
        orgId,
        dataSourceId,
        pageToken,
        bqPageToken,
        pageSize,
        pageNumber,
        dataCoreSvcDatasourcehdlRowsInput,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Gets rows from a data source with optional filtering and transformations
     * @summary Get DataSource Rows
     * @param {string} orgId
     * @param {string} dataSourceId
     * @param {string} [pageToken]
     * @param {string} [bqPageToken]
     * @param {number} [pageSize]
     * @param {number} [pageNumber]
     * @param {DataCoreSvcDatasourcehdlRowsInput} [dataCoreSvcDatasourcehdlRowsInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async handlersDatasourcehdlDataSourceHTTPHandlerRows2(
      orgId: string,
      dataSourceId: string,
      pageToken?: string,
      bqPageToken?: string,
      pageSize?: number,
      pageNumber?: number,
      dataCoreSvcDatasourcehdlRowsInput?: DataCoreSvcDatasourcehdlRowsInput,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataCoreSvcDatasourcehdlRowsOutput>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.handlersDatasourcehdlDataSourceHTTPHandlerRows2(
        orgId,
        dataSourceId,
        pageToken,
        bqPageToken,
        pageSize,
        pageNumber,
        dataCoreSvcDatasourcehdlRowsInput,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Gets the schema definition for a data source
     * @summary Get DataSource Schema
     * @param {string} orgId
     * @param {string} dataSourceId
     * @param {string} [pageToken]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async handlersDatasourcehdlDataSourceHTTPHandlerSchema(
      orgId: string,
      dataSourceId: string,
      pageToken?: string,
      pageSize?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataCoreSvcDatasourcehdlSchemaOutput>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.handlersDatasourcehdlDataSourceHTTPHandlerSchema(
        orgId,
        dataSourceId,
        pageToken,
        pageSize,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Gets the schema definition for a data source
     * @summary Get DataSource Schema
     * @param {string} orgId
     * @param {string} dataSourceId
     * @param {string} [pageToken]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async handlersDatasourcehdlDataSourceHTTPHandlerSchema2(
      orgId: string,
      dataSourceId: string,
      pageToken?: string,
      pageSize?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataCoreSvcDatasourcehdlSchemaOutput>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.handlersDatasourcehdlDataSourceHTTPHandlerSchema2(
        orgId,
        dataSourceId,
        pageToken,
        pageSize,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Validates a data source configuration and connection
     * @summary Validate DataSource
     * @param {string} orgId
     * @param {string} dataSourceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async handlersDatasourcehdlDataSourceHTTPHandlerValidate(
      orgId: string,
      dataSourceId: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataCoreSvcDatasourcesDataSourceValidationResult>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.handlersDatasourcehdlDataSourceHTTPHandlerValidate(
        orgId,
        dataSourceId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Validates a data source configuration and connection
     * @summary Validate DataSource
     * @param {string} orgId
     * @param {string} dataSourceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async handlersDatasourcehdlDataSourceHTTPHandlerValidate2(
      orgId: string,
      dataSourceId: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataCoreSvcDatasourcesDataSourceValidationResult>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.handlersDatasourcehdlDataSourceHTTPHandlerValidate2(
        orgId,
        dataSourceId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * DataSourcesApi - factory interface
 * @export
 */
export const DataSourcesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = DataSourcesApiFp(configuration);
  return {
    /**
     * Creates a new data source
     * @summary Create DataSource
     * @param {string} orgId
     * @param {DataCoreSvcDatasourcehdlCreateInput} [dataCoreSvcDatasourcehdlCreateInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersDatasourcehdlDataSourceHTTPHandlerCreate(
      orgId: string,
      dataCoreSvcDatasourcehdlCreateInput?: DataCoreSvcDatasourcehdlCreateInput,
      options?: any
    ): AxiosPromise<DataCoreSvcDatasourcesDataSourcePointer> {
      return localVarFp
        .handlersDatasourcehdlDataSourceHTTPHandlerCreate(orgId, dataCoreSvcDatasourcehdlCreateInput, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Creates a new data source
     * @summary Create DataSource
     * @param {string} orgId
     * @param {DataCoreSvcDatasourcehdlCreateInput} [dataCoreSvcDatasourcehdlCreateInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersDatasourcehdlDataSourceHTTPHandlerCreate2(
      orgId: string,
      dataCoreSvcDatasourcehdlCreateInput?: DataCoreSvcDatasourcehdlCreateInput,
      options?: any
    ): AxiosPromise<DataCoreSvcDatasourcesDataSourcePointer> {
      return localVarFp
        .handlersDatasourcehdlDataSourceHTTPHandlerCreate2(orgId, dataCoreSvcDatasourcehdlCreateInput, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Exports data source content to a downloadable file
     * @summary Export DataSource
     * @param {string} orgId
     * @param {string} dataSourceId
     * @param {string} [pageToken]
     * @param {string} [bqPageToken]
     * @param {number} [pageSize]
     * @param {number} [pageNumber]
     * @param {DataCoreSvcDatasourcehdlRowsInput} [dataCoreSvcDatasourcehdlRowsInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersDatasourcehdlDataSourceHTTPHandlerExport(
      orgId: string,
      dataSourceId: string,
      pageToken?: string,
      bqPageToken?: string,
      pageSize?: number,
      pageNumber?: number,
      dataCoreSvcDatasourcehdlRowsInput?: DataCoreSvcDatasourcehdlRowsInput,
      options?: any
    ): AxiosPromise<DataCoreSvcDatasourcehdlDownloadOutput> {
      return localVarFp
        .handlersDatasourcehdlDataSourceHTTPHandlerExport(
          orgId,
          dataSourceId,
          pageToken,
          bqPageToken,
          pageSize,
          pageNumber,
          dataCoreSvcDatasourcehdlRowsInput,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Exports data source content to a downloadable file
     * @summary Export DataSource
     * @param {string} orgId
     * @param {string} dataSourceId
     * @param {string} [pageToken]
     * @param {string} [bqPageToken]
     * @param {number} [pageSize]
     * @param {number} [pageNumber]
     * @param {DataCoreSvcDatasourcehdlRowsInput} [dataCoreSvcDatasourcehdlRowsInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersDatasourcehdlDataSourceHTTPHandlerExport2(
      orgId: string,
      dataSourceId: string,
      pageToken?: string,
      bqPageToken?: string,
      pageSize?: number,
      pageNumber?: number,
      dataCoreSvcDatasourcehdlRowsInput?: DataCoreSvcDatasourcehdlRowsInput,
      options?: any
    ): AxiosPromise<DataCoreSvcDatasourcehdlDownloadOutput> {
      return localVarFp
        .handlersDatasourcehdlDataSourceHTTPHandlerExport2(
          orgId,
          dataSourceId,
          pageToken,
          bqPageToken,
          pageSize,
          pageNumber,
          dataCoreSvcDatasourcehdlRowsInput,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns a signed URL for uploading a CSV file and a file pointer for subsequent operations
     * @summary Get CSV Upload URL
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersDatasourcehdlDataSourceHTTPHandlerGetCsvUploadUrl(
      orgId: string,
      options?: any
    ): AxiosPromise<DataCoreSvcDatasourcehdlCsvUploadUrlOutput> {
      return localVarFp
        .handlersDatasourcehdlDataSourceHTTPHandlerGetCsvUploadUrl(orgId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Returns a signed URL for uploading a CSV file and a file pointer for subsequent operations
     * @summary Get CSV Upload URL
     * @param {string} orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersDatasourcehdlDataSourceHTTPHandlerGetCsvUploadUrl2(
      orgId: string,
      options?: any
    ): AxiosPromise<DataCoreSvcDatasourcehdlCsvUploadUrlOutput> {
      return localVarFp
        .handlersDatasourcehdlDataSourceHTTPHandlerGetCsvUploadUrl2(orgId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Gets rows from a data source with optional filtering and transformations
     * @summary Get DataSource Rows
     * @param {string} orgId
     * @param {string} dataSourceId
     * @param {string} [pageToken]
     * @param {string} [bqPageToken]
     * @param {number} [pageSize]
     * @param {number} [pageNumber]
     * @param {DataCoreSvcDatasourcehdlRowsInput} [dataCoreSvcDatasourcehdlRowsInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersDatasourcehdlDataSourceHTTPHandlerRows(
      orgId: string,
      dataSourceId: string,
      pageToken?: string,
      bqPageToken?: string,
      pageSize?: number,
      pageNumber?: number,
      dataCoreSvcDatasourcehdlRowsInput?: DataCoreSvcDatasourcehdlRowsInput,
      options?: any
    ): AxiosPromise<DataCoreSvcDatasourcehdlRowsOutput> {
      return localVarFp
        .handlersDatasourcehdlDataSourceHTTPHandlerRows(
          orgId,
          dataSourceId,
          pageToken,
          bqPageToken,
          pageSize,
          pageNumber,
          dataCoreSvcDatasourcehdlRowsInput,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Gets rows from a data source with optional filtering and transformations
     * @summary Get DataSource Rows
     * @param {string} orgId
     * @param {string} dataSourceId
     * @param {string} [pageToken]
     * @param {string} [bqPageToken]
     * @param {number} [pageSize]
     * @param {number} [pageNumber]
     * @param {DataCoreSvcDatasourcehdlRowsInput} [dataCoreSvcDatasourcehdlRowsInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersDatasourcehdlDataSourceHTTPHandlerRows2(
      orgId: string,
      dataSourceId: string,
      pageToken?: string,
      bqPageToken?: string,
      pageSize?: number,
      pageNumber?: number,
      dataCoreSvcDatasourcehdlRowsInput?: DataCoreSvcDatasourcehdlRowsInput,
      options?: any
    ): AxiosPromise<DataCoreSvcDatasourcehdlRowsOutput> {
      return localVarFp
        .handlersDatasourcehdlDataSourceHTTPHandlerRows2(
          orgId,
          dataSourceId,
          pageToken,
          bqPageToken,
          pageSize,
          pageNumber,
          dataCoreSvcDatasourcehdlRowsInput,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Gets the schema definition for a data source
     * @summary Get DataSource Schema
     * @param {string} orgId
     * @param {string} dataSourceId
     * @param {string} [pageToken]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersDatasourcehdlDataSourceHTTPHandlerSchema(
      orgId: string,
      dataSourceId: string,
      pageToken?: string,
      pageSize?: number,
      options?: any
    ): AxiosPromise<DataCoreSvcDatasourcehdlSchemaOutput> {
      return localVarFp
        .handlersDatasourcehdlDataSourceHTTPHandlerSchema(orgId, dataSourceId, pageToken, pageSize, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Gets the schema definition for a data source
     * @summary Get DataSource Schema
     * @param {string} orgId
     * @param {string} dataSourceId
     * @param {string} [pageToken]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersDatasourcehdlDataSourceHTTPHandlerSchema2(
      orgId: string,
      dataSourceId: string,
      pageToken?: string,
      pageSize?: number,
      options?: any
    ): AxiosPromise<DataCoreSvcDatasourcehdlSchemaOutput> {
      return localVarFp
        .handlersDatasourcehdlDataSourceHTTPHandlerSchema2(orgId, dataSourceId, pageToken, pageSize, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Validates a data source configuration and connection
     * @summary Validate DataSource
     * @param {string} orgId
     * @param {string} dataSourceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersDatasourcehdlDataSourceHTTPHandlerValidate(
      orgId: string,
      dataSourceId: string,
      options?: any
    ): AxiosPromise<DataCoreSvcDatasourcesDataSourceValidationResult> {
      return localVarFp
        .handlersDatasourcehdlDataSourceHTTPHandlerValidate(orgId, dataSourceId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Validates a data source configuration and connection
     * @summary Validate DataSource
     * @param {string} orgId
     * @param {string} dataSourceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersDatasourcehdlDataSourceHTTPHandlerValidate2(
      orgId: string,
      dataSourceId: string,
      options?: any
    ): AxiosPromise<DataCoreSvcDatasourcesDataSourceValidationResult> {
      return localVarFp
        .handlersDatasourcehdlDataSourceHTTPHandlerValidate2(orgId, dataSourceId, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * DataSourcesApi - object-oriented interface
 * @export
 * @class DataSourcesApi
 * @extends {BaseAPI}
 */
export class DataSourcesApi extends BaseAPI {
  /**
   * Creates a new data source
   * @summary Create DataSource
   * @param {string} orgId
   * @param {DataCoreSvcDatasourcehdlCreateInput} [dataCoreSvcDatasourcehdlCreateInput]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataSourcesApi
   */
  public handlersDatasourcehdlDataSourceHTTPHandlerCreate(
    orgId: string,
    dataCoreSvcDatasourcehdlCreateInput?: DataCoreSvcDatasourcehdlCreateInput,
    options?: AxiosRequestConfig
  ) {
    return DataSourcesApiFp(this.configuration)
      .handlersDatasourcehdlDataSourceHTTPHandlerCreate(orgId, dataCoreSvcDatasourcehdlCreateInput, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Creates a new data source
   * @summary Create DataSource
   * @param {string} orgId
   * @param {DataCoreSvcDatasourcehdlCreateInput} [dataCoreSvcDatasourcehdlCreateInput]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataSourcesApi
   */
  public handlersDatasourcehdlDataSourceHTTPHandlerCreate2(
    orgId: string,
    dataCoreSvcDatasourcehdlCreateInput?: DataCoreSvcDatasourcehdlCreateInput,
    options?: AxiosRequestConfig
  ) {
    return DataSourcesApiFp(this.configuration)
      .handlersDatasourcehdlDataSourceHTTPHandlerCreate2(orgId, dataCoreSvcDatasourcehdlCreateInput, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Exports data source content to a downloadable file
   * @summary Export DataSource
   * @param {string} orgId
   * @param {string} dataSourceId
   * @param {string} [pageToken]
   * @param {string} [bqPageToken]
   * @param {number} [pageSize]
   * @param {number} [pageNumber]
   * @param {DataCoreSvcDatasourcehdlRowsInput} [dataCoreSvcDatasourcehdlRowsInput]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataSourcesApi
   */
  public handlersDatasourcehdlDataSourceHTTPHandlerExport(
    orgId: string,
    dataSourceId: string,
    pageToken?: string,
    bqPageToken?: string,
    pageSize?: number,
    pageNumber?: number,
    dataCoreSvcDatasourcehdlRowsInput?: DataCoreSvcDatasourcehdlRowsInput,
    options?: AxiosRequestConfig
  ) {
    return DataSourcesApiFp(this.configuration)
      .handlersDatasourcehdlDataSourceHTTPHandlerExport(
        orgId,
        dataSourceId,
        pageToken,
        bqPageToken,
        pageSize,
        pageNumber,
        dataCoreSvcDatasourcehdlRowsInput,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Exports data source content to a downloadable file
   * @summary Export DataSource
   * @param {string} orgId
   * @param {string} dataSourceId
   * @param {string} [pageToken]
   * @param {string} [bqPageToken]
   * @param {number} [pageSize]
   * @param {number} [pageNumber]
   * @param {DataCoreSvcDatasourcehdlRowsInput} [dataCoreSvcDatasourcehdlRowsInput]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataSourcesApi
   */
  public handlersDatasourcehdlDataSourceHTTPHandlerExport2(
    orgId: string,
    dataSourceId: string,
    pageToken?: string,
    bqPageToken?: string,
    pageSize?: number,
    pageNumber?: number,
    dataCoreSvcDatasourcehdlRowsInput?: DataCoreSvcDatasourcehdlRowsInput,
    options?: AxiosRequestConfig
  ) {
    return DataSourcesApiFp(this.configuration)
      .handlersDatasourcehdlDataSourceHTTPHandlerExport2(
        orgId,
        dataSourceId,
        pageToken,
        bqPageToken,
        pageSize,
        pageNumber,
        dataCoreSvcDatasourcehdlRowsInput,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns a signed URL for uploading a CSV file and a file pointer for subsequent operations
   * @summary Get CSV Upload URL
   * @param {string} orgId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataSourcesApi
   */
  public handlersDatasourcehdlDataSourceHTTPHandlerGetCsvUploadUrl(orgId: string, options?: AxiosRequestConfig) {
    return DataSourcesApiFp(this.configuration)
      .handlersDatasourcehdlDataSourceHTTPHandlerGetCsvUploadUrl(orgId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Returns a signed URL for uploading a CSV file and a file pointer for subsequent operations
   * @summary Get CSV Upload URL
   * @param {string} orgId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataSourcesApi
   */
  public handlersDatasourcehdlDataSourceHTTPHandlerGetCsvUploadUrl2(orgId: string, options?: AxiosRequestConfig) {
    return DataSourcesApiFp(this.configuration)
      .handlersDatasourcehdlDataSourceHTTPHandlerGetCsvUploadUrl2(orgId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Gets rows from a data source with optional filtering and transformations
   * @summary Get DataSource Rows
   * @param {string} orgId
   * @param {string} dataSourceId
   * @param {string} [pageToken]
   * @param {string} [bqPageToken]
   * @param {number} [pageSize]
   * @param {number} [pageNumber]
   * @param {DataCoreSvcDatasourcehdlRowsInput} [dataCoreSvcDatasourcehdlRowsInput]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataSourcesApi
   */
  public handlersDatasourcehdlDataSourceHTTPHandlerRows(
    orgId: string,
    dataSourceId: string,
    pageToken?: string,
    bqPageToken?: string,
    pageSize?: number,
    pageNumber?: number,
    dataCoreSvcDatasourcehdlRowsInput?: DataCoreSvcDatasourcehdlRowsInput,
    options?: AxiosRequestConfig
  ) {
    return DataSourcesApiFp(this.configuration)
      .handlersDatasourcehdlDataSourceHTTPHandlerRows(
        orgId,
        dataSourceId,
        pageToken,
        bqPageToken,
        pageSize,
        pageNumber,
        dataCoreSvcDatasourcehdlRowsInput,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Gets rows from a data source with optional filtering and transformations
   * @summary Get DataSource Rows
   * @param {string} orgId
   * @param {string} dataSourceId
   * @param {string} [pageToken]
   * @param {string} [bqPageToken]
   * @param {number} [pageSize]
   * @param {number} [pageNumber]
   * @param {DataCoreSvcDatasourcehdlRowsInput} [dataCoreSvcDatasourcehdlRowsInput]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataSourcesApi
   */
  public handlersDatasourcehdlDataSourceHTTPHandlerRows2(
    orgId: string,
    dataSourceId: string,
    pageToken?: string,
    bqPageToken?: string,
    pageSize?: number,
    pageNumber?: number,
    dataCoreSvcDatasourcehdlRowsInput?: DataCoreSvcDatasourcehdlRowsInput,
    options?: AxiosRequestConfig
  ) {
    return DataSourcesApiFp(this.configuration)
      .handlersDatasourcehdlDataSourceHTTPHandlerRows2(
        orgId,
        dataSourceId,
        pageToken,
        bqPageToken,
        pageSize,
        pageNumber,
        dataCoreSvcDatasourcehdlRowsInput,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Gets the schema definition for a data source
   * @summary Get DataSource Schema
   * @param {string} orgId
   * @param {string} dataSourceId
   * @param {string} [pageToken]
   * @param {number} [pageSize]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataSourcesApi
   */
  public handlersDatasourcehdlDataSourceHTTPHandlerSchema(
    orgId: string,
    dataSourceId: string,
    pageToken?: string,
    pageSize?: number,
    options?: AxiosRequestConfig
  ) {
    return DataSourcesApiFp(this.configuration)
      .handlersDatasourcehdlDataSourceHTTPHandlerSchema(orgId, dataSourceId, pageToken, pageSize, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Gets the schema definition for a data source
   * @summary Get DataSource Schema
   * @param {string} orgId
   * @param {string} dataSourceId
   * @param {string} [pageToken]
   * @param {number} [pageSize]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataSourcesApi
   */
  public handlersDatasourcehdlDataSourceHTTPHandlerSchema2(
    orgId: string,
    dataSourceId: string,
    pageToken?: string,
    pageSize?: number,
    options?: AxiosRequestConfig
  ) {
    return DataSourcesApiFp(this.configuration)
      .handlersDatasourcehdlDataSourceHTTPHandlerSchema2(orgId, dataSourceId, pageToken, pageSize, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Validates a data source configuration and connection
   * @summary Validate DataSource
   * @param {string} orgId
   * @param {string} dataSourceId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataSourcesApi
   */
  public handlersDatasourcehdlDataSourceHTTPHandlerValidate(
    orgId: string,
    dataSourceId: string,
    options?: AxiosRequestConfig
  ) {
    return DataSourcesApiFp(this.configuration)
      .handlersDatasourcehdlDataSourceHTTPHandlerValidate(orgId, dataSourceId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Validates a data source configuration and connection
   * @summary Validate DataSource
   * @param {string} orgId
   * @param {string} dataSourceId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DataSourcesApi
   */
  public handlersDatasourcehdlDataSourceHTTPHandlerValidate2(
    orgId: string,
    dataSourceId: string,
    options?: AxiosRequestConfig
  ) {
    return DataSourcesApiFp(this.configuration)
      .handlersDatasourcehdlDataSourceHTTPHandlerValidate2(orgId, dataSourceId, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary data Source HTTP Handler Delete
     * @param {string} orgId
     * @param {string} dataSourceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersDatasourcehdlDataSourceHTTPHandlerDelete: async (
      orgId: string,
      dataSourceId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('handlersDatasourcehdlDataSourceHTTPHandlerDelete', 'orgId', orgId);
      // verify required parameter 'dataSourceId' is not null or undefined
      assertParamExists('handlersDatasourcehdlDataSourceHTTPHandlerDelete', 'dataSourceId', dataSourceId);
      const localVarPath = `/v3/orgs/{orgId}/data-sources/{dataSourceId}/`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'dataSourceId'}}`, encodeURIComponent(String(dataSourceId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary data Source HTTP Handler Delete
     * @param {string} orgId
     * @param {string} dataSourceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersDatasourcehdlDataSourceHTTPHandlerDelete2: async (
      orgId: string,
      dataSourceId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('handlersDatasourcehdlDataSourceHTTPHandlerDelete2', 'orgId', orgId);
      // verify required parameter 'dataSourceId' is not null or undefined
      assertParamExists('handlersDatasourcehdlDataSourceHTTPHandlerDelete2', 'dataSourceId', dataSourceId);
      const localVarPath = `/orgs/{orgId}/data-sources/{dataSourceId}/`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'dataSourceId'}}`, encodeURIComponent(String(dataSourceId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary data Source HTTP Handler Get
     * @param {string} orgId
     * @param {string} dataSourceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersDatasourcehdlDataSourceHTTPHandlerGet: async (
      orgId: string,
      dataSourceId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('handlersDatasourcehdlDataSourceHTTPHandlerGet', 'orgId', orgId);
      // verify required parameter 'dataSourceId' is not null or undefined
      assertParamExists('handlersDatasourcehdlDataSourceHTTPHandlerGet', 'dataSourceId', dataSourceId);
      const localVarPath = `/v3/orgs/{orgId}/data-sources/{dataSourceId}/`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'dataSourceId'}}`, encodeURIComponent(String(dataSourceId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary data Source HTTP Handler Get
     * @param {string} orgId
     * @param {string} dataSourceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersDatasourcehdlDataSourceHTTPHandlerGet2: async (
      orgId: string,
      dataSourceId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('handlersDatasourcehdlDataSourceHTTPHandlerGet2', 'orgId', orgId);
      // verify required parameter 'dataSourceId' is not null or undefined
      assertParamExists('handlersDatasourcehdlDataSourceHTTPHandlerGet2', 'dataSourceId', dataSourceId);
      const localVarPath = `/orgs/{orgId}/data-sources/{dataSourceId}/`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'dataSourceId'}}`, encodeURIComponent(String(dataSourceId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary data Source HTTP Handler Get Big Query Table Pointer
     * @param {string} orgId
     * @param {string} dataSourceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersDatasourcehdlDataSourceHTTPHandlerGetBigQueryTablePointer: async (
      orgId: string,
      dataSourceId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('handlersDatasourcehdlDataSourceHTTPHandlerGetBigQueryTablePointer', 'orgId', orgId);
      // verify required parameter 'dataSourceId' is not null or undefined
      assertParamExists(
        'handlersDatasourcehdlDataSourceHTTPHandlerGetBigQueryTablePointer',
        'dataSourceId',
        dataSourceId
      );
      const localVarPath = `/v3/orgs/{orgId}/data-sources/{dataSourceId}/bq-table`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'dataSourceId'}}`, encodeURIComponent(String(dataSourceId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary data Source HTTP Handler Get Big Query Table Pointer
     * @param {string} orgId
     * @param {string} dataSourceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersDatasourcehdlDataSourceHTTPHandlerGetBigQueryTablePointer2: async (
      orgId: string,
      dataSourceId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('handlersDatasourcehdlDataSourceHTTPHandlerGetBigQueryTablePointer2', 'orgId', orgId);
      // verify required parameter 'dataSourceId' is not null or undefined
      assertParamExists(
        'handlersDatasourcehdlDataSourceHTTPHandlerGetBigQueryTablePointer2',
        'dataSourceId',
        dataSourceId
      );
      const localVarPath = `/orgs/{orgId}/data-sources/{dataSourceId}/bq-table`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'dataSourceId'}}`, encodeURIComponent(String(dataSourceId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary data Source HTTP Handler List
     * @param {string} orgId
     * @param {string} [matchesFeedSchemaType]
     * @param {string} [pageToken]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersDatasourcehdlDataSourceHTTPHandlerList: async (
      orgId: string,
      matchesFeedSchemaType?: string,
      pageToken?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('handlersDatasourcehdlDataSourceHTTPHandlerList', 'orgId', orgId);
      const localVarPath = `/v3/orgs/{orgId}/data-sources/`.replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (matchesFeedSchemaType !== undefined) {
        localVarQueryParameter['matchesFeedSchemaType'] = matchesFeedSchemaType;
      }

      if (pageToken !== undefined) {
        localVarQueryParameter['pageToken'] = pageToken;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary data Source HTTP Handler List
     * @param {string} orgId
     * @param {string} [matchesFeedSchemaType]
     * @param {string} [pageToken]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersDatasourcehdlDataSourceHTTPHandlerList2: async (
      orgId: string,
      matchesFeedSchemaType?: string,
      pageToken?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('handlersDatasourcehdlDataSourceHTTPHandlerList2', 'orgId', orgId);
      const localVarPath = `/orgs/{orgId}/data-sources/`.replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (matchesFeedSchemaType !== undefined) {
        localVarQueryParameter['matchesFeedSchemaType'] = matchesFeedSchemaType;
      }

      if (pageToken !== undefined) {
        localVarQueryParameter['pageToken'] = pageToken;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary data Source HTTP Handler List Paginated
     * @param {string} orgId
     * @param {string} [matchesFeedSchemaType]
     * @param {string} [pageToken]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersDatasourcehdlDataSourceHTTPHandlerListPaginated: async (
      orgId: string,
      matchesFeedSchemaType?: string,
      pageToken?: string,
      pageSize?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('handlersDatasourcehdlDataSourceHTTPHandlerListPaginated', 'orgId', orgId);
      const localVarPath = `/v3/orgs/{orgId}/data-sources/paginated`.replace(
        `{${'orgId'}}`,
        encodeURIComponent(String(orgId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (matchesFeedSchemaType !== undefined) {
        localVarQueryParameter['matchesFeedSchemaType'] = matchesFeedSchemaType;
      }

      if (pageToken !== undefined) {
        localVarQueryParameter['pageToken'] = pageToken;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['pageSize'] = pageSize;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary data Source HTTP Handler List Paginated
     * @param {string} orgId
     * @param {string} [matchesFeedSchemaType]
     * @param {string} [pageToken]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersDatasourcehdlDataSourceHTTPHandlerListPaginated2: async (
      orgId: string,
      matchesFeedSchemaType?: string,
      pageToken?: string,
      pageSize?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('handlersDatasourcehdlDataSourceHTTPHandlerListPaginated2', 'orgId', orgId);
      const localVarPath = `/orgs/{orgId}/data-sources/paginated`.replace(
        `{${'orgId'}}`,
        encodeURIComponent(String(orgId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (matchesFeedSchemaType !== undefined) {
        localVarQueryParameter['matchesFeedSchemaType'] = matchesFeedSchemaType;
      }

      if (pageToken !== undefined) {
        localVarQueryParameter['pageToken'] = pageToken;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['pageSize'] = pageSize;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary feed HTTP Handler Archive
     * @param {string} orgId Organization ID
     * @param {string} feedId Feed ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersFeedhdlFeedHTTPHandlerArchive: async (
      orgId: string,
      feedId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('handlersFeedhdlFeedHTTPHandlerArchive', 'orgId', orgId);
      // verify required parameter 'feedId' is not null or undefined
      assertParamExists('handlersFeedhdlFeedHTTPHandlerArchive', 'feedId', feedId);
      const localVarPath = `/orgs/{orgId}/feeds/{feedId}/archive`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'feedId'}}`, encodeURIComponent(String(feedId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary feed HTTP Handler Create
     * @param {string} orgId Organization ID
     * @param {DataCoreSvcFeedhdlCreateFeedInput} [dataCoreSvcFeedhdlCreateFeedInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersFeedhdlFeedHTTPHandlerCreate: async (
      orgId: string,
      dataCoreSvcFeedhdlCreateFeedInput?: DataCoreSvcFeedhdlCreateFeedInput,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('handlersFeedhdlFeedHTTPHandlerCreate', 'orgId', orgId);
      const localVarPath = `/orgs/{orgId}/feeds/`.replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        dataCoreSvcFeedhdlCreateFeedInput,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary feed HTTP Handler Delete
     * @param {string} orgId Organization ID
     * @param {string} feedId Feed ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersFeedhdlFeedHTTPHandlerDelete: async (
      orgId: string,
      feedId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('handlersFeedhdlFeedHTTPHandlerDelete', 'orgId', orgId);
      // verify required parameter 'feedId' is not null or undefined
      assertParamExists('handlersFeedhdlFeedHTTPHandlerDelete', 'feedId', feedId);
      const localVarPath = `/orgs/{orgId}/feeds/{feedId}/`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'feedId'}}`, encodeURIComponent(String(feedId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary feed HTTP Handler Get
     * @param {string} orgId Organization ID
     * @param {string} feedId Feed ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersFeedhdlFeedHTTPHandlerGet: async (
      orgId: string,
      feedId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('handlersFeedhdlFeedHTTPHandlerGet', 'orgId', orgId);
      // verify required parameter 'feedId' is not null or undefined
      assertParamExists('handlersFeedhdlFeedHTTPHandlerGet', 'feedId', feedId);
      const localVarPath = `/orgs/{orgId}/feeds/{feedId}/`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'feedId'}}`, encodeURIComponent(String(feedId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary feed HTTP Handler Get Feed Validation Status
     * @param {string} orgId Organization ID
     * @param {string} feedId Feed ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersFeedhdlFeedHTTPHandlerGetFeedValidationStatus: async (
      orgId: string,
      feedId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('handlersFeedhdlFeedHTTPHandlerGetFeedValidationStatus', 'orgId', orgId);
      // verify required parameter 'feedId' is not null or undefined
      assertParamExists('handlersFeedhdlFeedHTTPHandlerGetFeedValidationStatus', 'feedId', feedId);
      const localVarPath = `/orgs/{orgId}/feeds/{feedId}/validation`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'feedId'}}`, encodeURIComponent(String(feedId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary feed HTTP Handler List
     * @param {string} orgId Organization ID
     * @param {string} [pageToken]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersFeedhdlFeedHTTPHandlerList: async (
      orgId: string,
      pageToken?: string,
      pageSize?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('handlersFeedhdlFeedHTTPHandlerList', 'orgId', orgId);
      const localVarPath = `/orgs/{orgId}/feeds/`.replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (pageToken !== undefined) {
        localVarQueryParameter['pageToken'] = pageToken;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['pageSize'] = pageSize;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary feed HTTP Handler Update Feed Approval
     * @param {string} orgId Organization ID
     * @param {string} feedId Feed ID
     * @param {DataCoreSvcFeedhdlUpdateFeedApprovalInput} [dataCoreSvcFeedhdlUpdateFeedApprovalInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersFeedhdlFeedHTTPHandlerUpdateFeedApproval: async (
      orgId: string,
      feedId: string,
      dataCoreSvcFeedhdlUpdateFeedApprovalInput?: DataCoreSvcFeedhdlUpdateFeedApprovalInput,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('handlersFeedhdlFeedHTTPHandlerUpdateFeedApproval', 'orgId', orgId);
      // verify required parameter 'feedId' is not null or undefined
      assertParamExists('handlersFeedhdlFeedHTTPHandlerUpdateFeedApproval', 'feedId', feedId);
      const localVarPath = `/orgs/{orgId}/feeds/{feedId}/approval`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'feedId'}}`, encodeURIComponent(String(feedId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        dataCoreSvcFeedhdlUpdateFeedApprovalInput,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Feed Run Handler Init Manual Run
     * @param {string} orgId
     * @param {string} feedId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersFeedrunhdlFeedRunHandlerInitManualRun: async (
      orgId: string,
      feedId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('handlersFeedrunhdlFeedRunHandlerInitManualRun', 'orgId', orgId);
      // verify required parameter 'feedId' is not null or undefined
      assertParamExists('handlersFeedrunhdlFeedRunHandlerInitManualRun', 'feedId', feedId);
      const localVarPath = `/orgs/{orgId}/feeds/{feedId}/runs/`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'feedId'}}`, encodeURIComponent(String(feedId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Feed Run Handler List Runs
     * @param {string} orgId
     * @param {string} feedId
     * @param {object} [filter]
     * @param {string} [pageToken]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersFeedrunhdlFeedRunHandlerListRuns: async (
      orgId: string,
      feedId: string,
      filter?: object,
      pageToken?: string,
      pageSize?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('handlersFeedrunhdlFeedRunHandlerListRuns', 'orgId', orgId);
      // verify required parameter 'feedId' is not null or undefined
      assertParamExists('handlersFeedrunhdlFeedRunHandlerListRuns', 'feedId', feedId);
      const localVarPath = `/orgs/{orgId}/feeds/{feedId}/runs/`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'feedId'}}`, encodeURIComponent(String(feedId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (filter !== undefined) {
        localVarQueryParameter['filter'] = filter;
      }

      if (pageToken !== undefined) {
        localVarQueryParameter['pageToken'] = pageToken;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['pageSize'] = pageSize;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Feed Run Handler Start Sync
     * @param {string} orgId
     * @param {string} feedId
     * @param {string} feedRunId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersFeedrunhdlFeedRunHandlerStartSync: async (
      orgId: string,
      feedId: string,
      feedRunId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('handlersFeedrunhdlFeedRunHandlerStartSync', 'orgId', orgId);
      // verify required parameter 'feedId' is not null or undefined
      assertParamExists('handlersFeedrunhdlFeedRunHandlerStartSync', 'feedId', feedId);
      // verify required parameter 'feedRunId' is not null or undefined
      assertParamExists('handlersFeedrunhdlFeedRunHandlerStartSync', 'feedRunId', feedRunId);
      const localVarPath = `/orgs/{orgId}/feeds/{feedId}/runs/{feedRunId}/start`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'feedId'}}`, encodeURIComponent(String(feedId)))
        .replace(`{${'feedRunId'}}`, encodeURIComponent(String(feedRunId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary import HTTP Handler Get
     * @param {string} orgId
     * @param {string} importId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersImporthdlImportHTTPHandlerGet: async (
      orgId: string,
      importId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('handlersImporthdlImportHTTPHandlerGet', 'orgId', orgId);
      // verify required parameter 'importId' is not null or undefined
      assertParamExists('handlersImporthdlImportHTTPHandlerGet', 'importId', importId);
      const localVarPath = `/orgs/{orgId}/imports/{importId}/`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'importId'}}`, encodeURIComponent(String(importId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary data Source HTTP Handler Delete
     * @param {string} orgId
     * @param {string} dataSourceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async handlersDatasourcehdlDataSourceHTTPHandlerDelete(
      orgId: string,
      dataSourceId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.handlersDatasourcehdlDataSourceHTTPHandlerDelete(
        orgId,
        dataSourceId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary data Source HTTP Handler Delete
     * @param {string} orgId
     * @param {string} dataSourceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async handlersDatasourcehdlDataSourceHTTPHandlerDelete2(
      orgId: string,
      dataSourceId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.handlersDatasourcehdlDataSourceHTTPHandlerDelete2(
        orgId,
        dataSourceId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary data Source HTTP Handler Get
     * @param {string} orgId
     * @param {string} dataSourceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async handlersDatasourcehdlDataSourceHTTPHandlerGet(
      orgId: string,
      dataSourceId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataCoreSvcDatasourcesDataSourceUnion>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.handlersDatasourcehdlDataSourceHTTPHandlerGet(
        orgId,
        dataSourceId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary data Source HTTP Handler Get
     * @param {string} orgId
     * @param {string} dataSourceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async handlersDatasourcehdlDataSourceHTTPHandlerGet2(
      orgId: string,
      dataSourceId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataCoreSvcDatasourcesDataSourceUnion>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.handlersDatasourcehdlDataSourceHTTPHandlerGet2(
        orgId,
        dataSourceId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary data Source HTTP Handler Get Big Query Table Pointer
     * @param {string} orgId
     * @param {string} dataSourceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async handlersDatasourcehdlDataSourceHTTPHandlerGetBigQueryTablePointer(
      orgId: string,
      dataSourceId: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataCoreSvcDatasourcehdlBqTablePointerOutput>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.handlersDatasourcehdlDataSourceHTTPHandlerGetBigQueryTablePointer(
          orgId,
          dataSourceId,
          options
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary data Source HTTP Handler Get Big Query Table Pointer
     * @param {string} orgId
     * @param {string} dataSourceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async handlersDatasourcehdlDataSourceHTTPHandlerGetBigQueryTablePointer2(
      orgId: string,
      dataSourceId: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataCoreSvcDatasourcehdlBqTablePointerOutput>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.handlersDatasourcehdlDataSourceHTTPHandlerGetBigQueryTablePointer2(
          orgId,
          dataSourceId,
          options
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary data Source HTTP Handler List
     * @param {string} orgId
     * @param {string} [matchesFeedSchemaType]
     * @param {string} [pageToken]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async handlersDatasourcehdlDataSourceHTTPHandlerList(
      orgId: string,
      matchesFeedSchemaType?: string,
      pageToken?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataCoreSvcDatasourcehdlListOutput>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.handlersDatasourcehdlDataSourceHTTPHandlerList(
        orgId,
        matchesFeedSchemaType,
        pageToken,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary data Source HTTP Handler List
     * @param {string} orgId
     * @param {string} [matchesFeedSchemaType]
     * @param {string} [pageToken]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async handlersDatasourcehdlDataSourceHTTPHandlerList2(
      orgId: string,
      matchesFeedSchemaType?: string,
      pageToken?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataCoreSvcDatasourcehdlListOutput>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.handlersDatasourcehdlDataSourceHTTPHandlerList2(
        orgId,
        matchesFeedSchemaType,
        pageToken,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary data Source HTTP Handler List Paginated
     * @param {string} orgId
     * @param {string} [matchesFeedSchemaType]
     * @param {string} [pageToken]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async handlersDatasourcehdlDataSourceHTTPHandlerListPaginated(
      orgId: string,
      matchesFeedSchemaType?: string,
      pageToken?: string,
      pageSize?: number,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataCoreSvcDatasourcehdlListPaginatedOutput>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.handlersDatasourcehdlDataSourceHTTPHandlerListPaginated(
        orgId,
        matchesFeedSchemaType,
        pageToken,
        pageSize,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary data Source HTTP Handler List Paginated
     * @param {string} orgId
     * @param {string} [matchesFeedSchemaType]
     * @param {string} [pageToken]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async handlersDatasourcehdlDataSourceHTTPHandlerListPaginated2(
      orgId: string,
      matchesFeedSchemaType?: string,
      pageToken?: string,
      pageSize?: number,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataCoreSvcDatasourcehdlListPaginatedOutput>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.handlersDatasourcehdlDataSourceHTTPHandlerListPaginated2(
          orgId,
          matchesFeedSchemaType,
          pageToken,
          pageSize,
          options
        );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary feed HTTP Handler Archive
     * @param {string} orgId Organization ID
     * @param {string} feedId Feed ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async handlersFeedhdlFeedHTTPHandlerArchive(
      orgId: string,
      feedId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.handlersFeedhdlFeedHTTPHandlerArchive(
        orgId,
        feedId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary feed HTTP Handler Create
     * @param {string} orgId Organization ID
     * @param {DataCoreSvcFeedhdlCreateFeedInput} [dataCoreSvcFeedhdlCreateFeedInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async handlersFeedhdlFeedHTTPHandlerCreate(
      orgId: string,
      dataCoreSvcFeedhdlCreateFeedInput?: DataCoreSvcFeedhdlCreateFeedInput,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataCoreSvcFeedhdlCreateFeedOutput>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.handlersFeedhdlFeedHTTPHandlerCreate(
        orgId,
        dataCoreSvcFeedhdlCreateFeedInput,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary feed HTTP Handler Delete
     * @param {string} orgId Organization ID
     * @param {string} feedId Feed ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async handlersFeedhdlFeedHTTPHandlerDelete(
      orgId: string,
      feedId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.handlersFeedhdlFeedHTTPHandlerDelete(
        orgId,
        feedId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary feed HTTP Handler Get
     * @param {string} orgId Organization ID
     * @param {string} feedId Feed ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async handlersFeedhdlFeedHTTPHandlerGet(
      orgId: string,
      feedId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataCoreSvcFeedhdlGetFeedOutput>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.handlersFeedhdlFeedHTTPHandlerGet(
        orgId,
        feedId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary feed HTTP Handler Get Feed Validation Status
     * @param {string} orgId Organization ID
     * @param {string} feedId Feed ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async handlersFeedhdlFeedHTTPHandlerGetFeedValidationStatus(
      orgId: string,
      feedId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataCoreSvcFeedhdlFeedValidationOutput>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.handlersFeedhdlFeedHTTPHandlerGetFeedValidationStatus(
        orgId,
        feedId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary feed HTTP Handler List
     * @param {string} orgId Organization ID
     * @param {string} [pageToken]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async handlersFeedhdlFeedHTTPHandlerList(
      orgId: string,
      pageToken?: string,
      pageSize?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataCoreSvcFeedhdlListOutput>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.handlersFeedhdlFeedHTTPHandlerList(
        orgId,
        pageToken,
        pageSize,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary feed HTTP Handler Update Feed Approval
     * @param {string} orgId Organization ID
     * @param {string} feedId Feed ID
     * @param {DataCoreSvcFeedhdlUpdateFeedApprovalInput} [dataCoreSvcFeedhdlUpdateFeedApprovalInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async handlersFeedhdlFeedHTTPHandlerUpdateFeedApproval(
      orgId: string,
      feedId: string,
      dataCoreSvcFeedhdlUpdateFeedApprovalInput?: DataCoreSvcFeedhdlUpdateFeedApprovalInput,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataCoreSvcFeedhdlUpdateFeedApprovalOutput>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.handlersFeedhdlFeedHTTPHandlerUpdateFeedApproval(
        orgId,
        feedId,
        dataCoreSvcFeedhdlUpdateFeedApprovalInput,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Feed Run Handler Init Manual Run
     * @param {string} orgId
     * @param {string} feedId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async handlersFeedrunhdlFeedRunHandlerInitManualRun(
      orgId: string,
      feedId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataCoreSvcFeedrunhdlInitManualRunOutput>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.handlersFeedrunhdlFeedRunHandlerInitManualRun(
        orgId,
        feedId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Feed Run Handler List Runs
     * @param {string} orgId
     * @param {string} feedId
     * @param {object} [filter]
     * @param {string} [pageToken]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async handlersFeedrunhdlFeedRunHandlerListRuns(
      orgId: string,
      feedId: string,
      filter?: object,
      pageToken?: string,
      pageSize?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataCoreSvcFeedrunhdlListRunsOutput>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.handlersFeedrunhdlFeedRunHandlerListRuns(
        orgId,
        feedId,
        filter,
        pageToken,
        pageSize,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Feed Run Handler Start Sync
     * @param {string} orgId
     * @param {string} feedId
     * @param {string} feedRunId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async handlersFeedrunhdlFeedRunHandlerStartSync(
      orgId: string,
      feedId: string,
      feedRunId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataCoreSvcFeedrunhdlStartManualRunOutput>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.handlersFeedrunhdlFeedRunHandlerStartSync(
        orgId,
        feedId,
        feedRunId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary import HTTP Handler Get
     * @param {string} orgId
     * @param {string} importId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async handlersImporthdlImportHTTPHandlerGet(
      orgId: string,
      importId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataCoreSvcImporthdlGetOutput>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.handlersImporthdlImportHTTPHandlerGet(
        orgId,
        importId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = DefaultApiFp(configuration);
  return {
    /**
     *
     * @summary data Source HTTP Handler Delete
     * @param {string} orgId
     * @param {string} dataSourceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersDatasourcehdlDataSourceHTTPHandlerDelete(
      orgId: string,
      dataSourceId: string,
      options?: any
    ): AxiosPromise<string> {
      return localVarFp
        .handlersDatasourcehdlDataSourceHTTPHandlerDelete(orgId, dataSourceId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary data Source HTTP Handler Delete
     * @param {string} orgId
     * @param {string} dataSourceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersDatasourcehdlDataSourceHTTPHandlerDelete2(
      orgId: string,
      dataSourceId: string,
      options?: any
    ): AxiosPromise<string> {
      return localVarFp
        .handlersDatasourcehdlDataSourceHTTPHandlerDelete2(orgId, dataSourceId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary data Source HTTP Handler Get
     * @param {string} orgId
     * @param {string} dataSourceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersDatasourcehdlDataSourceHTTPHandlerGet(
      orgId: string,
      dataSourceId: string,
      options?: any
    ): AxiosPromise<DataCoreSvcDatasourcesDataSourceUnion> {
      return localVarFp
        .handlersDatasourcehdlDataSourceHTTPHandlerGet(orgId, dataSourceId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary data Source HTTP Handler Get
     * @param {string} orgId
     * @param {string} dataSourceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersDatasourcehdlDataSourceHTTPHandlerGet2(
      orgId: string,
      dataSourceId: string,
      options?: any
    ): AxiosPromise<DataCoreSvcDatasourcesDataSourceUnion> {
      return localVarFp
        .handlersDatasourcehdlDataSourceHTTPHandlerGet2(orgId, dataSourceId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary data Source HTTP Handler Get Big Query Table Pointer
     * @param {string} orgId
     * @param {string} dataSourceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersDatasourcehdlDataSourceHTTPHandlerGetBigQueryTablePointer(
      orgId: string,
      dataSourceId: string,
      options?: any
    ): AxiosPromise<DataCoreSvcDatasourcehdlBqTablePointerOutput> {
      return localVarFp
        .handlersDatasourcehdlDataSourceHTTPHandlerGetBigQueryTablePointer(orgId, dataSourceId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary data Source HTTP Handler Get Big Query Table Pointer
     * @param {string} orgId
     * @param {string} dataSourceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersDatasourcehdlDataSourceHTTPHandlerGetBigQueryTablePointer2(
      orgId: string,
      dataSourceId: string,
      options?: any
    ): AxiosPromise<DataCoreSvcDatasourcehdlBqTablePointerOutput> {
      return localVarFp
        .handlersDatasourcehdlDataSourceHTTPHandlerGetBigQueryTablePointer2(orgId, dataSourceId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary data Source HTTP Handler List
     * @param {string} orgId
     * @param {string} [matchesFeedSchemaType]
     * @param {string} [pageToken]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersDatasourcehdlDataSourceHTTPHandlerList(
      orgId: string,
      matchesFeedSchemaType?: string,
      pageToken?: string,
      options?: any
    ): AxiosPromise<DataCoreSvcDatasourcehdlListOutput> {
      return localVarFp
        .handlersDatasourcehdlDataSourceHTTPHandlerList(orgId, matchesFeedSchemaType, pageToken, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary data Source HTTP Handler List
     * @param {string} orgId
     * @param {string} [matchesFeedSchemaType]
     * @param {string} [pageToken]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersDatasourcehdlDataSourceHTTPHandlerList2(
      orgId: string,
      matchesFeedSchemaType?: string,
      pageToken?: string,
      options?: any
    ): AxiosPromise<DataCoreSvcDatasourcehdlListOutput> {
      return localVarFp
        .handlersDatasourcehdlDataSourceHTTPHandlerList2(orgId, matchesFeedSchemaType, pageToken, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary data Source HTTP Handler List Paginated
     * @param {string} orgId
     * @param {string} [matchesFeedSchemaType]
     * @param {string} [pageToken]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersDatasourcehdlDataSourceHTTPHandlerListPaginated(
      orgId: string,
      matchesFeedSchemaType?: string,
      pageToken?: string,
      pageSize?: number,
      options?: any
    ): AxiosPromise<DataCoreSvcDatasourcehdlListPaginatedOutput> {
      return localVarFp
        .handlersDatasourcehdlDataSourceHTTPHandlerListPaginated(
          orgId,
          matchesFeedSchemaType,
          pageToken,
          pageSize,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary data Source HTTP Handler List Paginated
     * @param {string} orgId
     * @param {string} [matchesFeedSchemaType]
     * @param {string} [pageToken]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersDatasourcehdlDataSourceHTTPHandlerListPaginated2(
      orgId: string,
      matchesFeedSchemaType?: string,
      pageToken?: string,
      pageSize?: number,
      options?: any
    ): AxiosPromise<DataCoreSvcDatasourcehdlListPaginatedOutput> {
      return localVarFp
        .handlersDatasourcehdlDataSourceHTTPHandlerListPaginated2(
          orgId,
          matchesFeedSchemaType,
          pageToken,
          pageSize,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary feed HTTP Handler Archive
     * @param {string} orgId Organization ID
     * @param {string} feedId Feed ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersFeedhdlFeedHTTPHandlerArchive(orgId: string, feedId: string, options?: any): AxiosPromise<string> {
      return localVarFp
        .handlersFeedhdlFeedHTTPHandlerArchive(orgId, feedId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary feed HTTP Handler Create
     * @param {string} orgId Organization ID
     * @param {DataCoreSvcFeedhdlCreateFeedInput} [dataCoreSvcFeedhdlCreateFeedInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersFeedhdlFeedHTTPHandlerCreate(
      orgId: string,
      dataCoreSvcFeedhdlCreateFeedInput?: DataCoreSvcFeedhdlCreateFeedInput,
      options?: any
    ): AxiosPromise<DataCoreSvcFeedhdlCreateFeedOutput> {
      return localVarFp
        .handlersFeedhdlFeedHTTPHandlerCreate(orgId, dataCoreSvcFeedhdlCreateFeedInput, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary feed HTTP Handler Delete
     * @param {string} orgId Organization ID
     * @param {string} feedId Feed ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersFeedhdlFeedHTTPHandlerDelete(orgId: string, feedId: string, options?: any): AxiosPromise<string> {
      return localVarFp
        .handlersFeedhdlFeedHTTPHandlerDelete(orgId, feedId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary feed HTTP Handler Get
     * @param {string} orgId Organization ID
     * @param {string} feedId Feed ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersFeedhdlFeedHTTPHandlerGet(
      orgId: string,
      feedId: string,
      options?: any
    ): AxiosPromise<DataCoreSvcFeedhdlGetFeedOutput> {
      return localVarFp
        .handlersFeedhdlFeedHTTPHandlerGet(orgId, feedId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary feed HTTP Handler Get Feed Validation Status
     * @param {string} orgId Organization ID
     * @param {string} feedId Feed ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersFeedhdlFeedHTTPHandlerGetFeedValidationStatus(
      orgId: string,
      feedId: string,
      options?: any
    ): AxiosPromise<DataCoreSvcFeedhdlFeedValidationOutput> {
      return localVarFp
        .handlersFeedhdlFeedHTTPHandlerGetFeedValidationStatus(orgId, feedId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary feed HTTP Handler List
     * @param {string} orgId Organization ID
     * @param {string} [pageToken]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersFeedhdlFeedHTTPHandlerList(
      orgId: string,
      pageToken?: string,
      pageSize?: number,
      options?: any
    ): AxiosPromise<DataCoreSvcFeedhdlListOutput> {
      return localVarFp
        .handlersFeedhdlFeedHTTPHandlerList(orgId, pageToken, pageSize, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary feed HTTP Handler Update Feed Approval
     * @param {string} orgId Organization ID
     * @param {string} feedId Feed ID
     * @param {DataCoreSvcFeedhdlUpdateFeedApprovalInput} [dataCoreSvcFeedhdlUpdateFeedApprovalInput]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersFeedhdlFeedHTTPHandlerUpdateFeedApproval(
      orgId: string,
      feedId: string,
      dataCoreSvcFeedhdlUpdateFeedApprovalInput?: DataCoreSvcFeedhdlUpdateFeedApprovalInput,
      options?: any
    ): AxiosPromise<DataCoreSvcFeedhdlUpdateFeedApprovalOutput> {
      return localVarFp
        .handlersFeedhdlFeedHTTPHandlerUpdateFeedApproval(
          orgId,
          feedId,
          dataCoreSvcFeedhdlUpdateFeedApprovalInput,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Feed Run Handler Init Manual Run
     * @param {string} orgId
     * @param {string} feedId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersFeedrunhdlFeedRunHandlerInitManualRun(
      orgId: string,
      feedId: string,
      options?: any
    ): AxiosPromise<DataCoreSvcFeedrunhdlInitManualRunOutput> {
      return localVarFp
        .handlersFeedrunhdlFeedRunHandlerInitManualRun(orgId, feedId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Feed Run Handler List Runs
     * @param {string} orgId
     * @param {string} feedId
     * @param {object} [filter]
     * @param {string} [pageToken]
     * @param {number} [pageSize]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersFeedrunhdlFeedRunHandlerListRuns(
      orgId: string,
      feedId: string,
      filter?: object,
      pageToken?: string,
      pageSize?: number,
      options?: any
    ): AxiosPromise<DataCoreSvcFeedrunhdlListRunsOutput> {
      return localVarFp
        .handlersFeedrunhdlFeedRunHandlerListRuns(orgId, feedId, filter, pageToken, pageSize, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Feed Run Handler Start Sync
     * @param {string} orgId
     * @param {string} feedId
     * @param {string} feedRunId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersFeedrunhdlFeedRunHandlerStartSync(
      orgId: string,
      feedId: string,
      feedRunId: string,
      options?: any
    ): AxiosPromise<DataCoreSvcFeedrunhdlStartManualRunOutput> {
      return localVarFp
        .handlersFeedrunhdlFeedRunHandlerStartSync(orgId, feedId, feedRunId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary import HTTP Handler Get
     * @param {string} orgId
     * @param {string} importId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersImporthdlImportHTTPHandlerGet(
      orgId: string,
      importId: string,
      options?: any
    ): AxiosPromise<DataCoreSvcImporthdlGetOutput> {
      return localVarFp
        .handlersImporthdlImportHTTPHandlerGet(orgId, importId, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
  /**
   *
   * @summary data Source HTTP Handler Delete
   * @param {string} orgId
   * @param {string} dataSourceId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public handlersDatasourcehdlDataSourceHTTPHandlerDelete(
    orgId: string,
    dataSourceId: string,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .handlersDatasourcehdlDataSourceHTTPHandlerDelete(orgId, dataSourceId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary data Source HTTP Handler Delete
   * @param {string} orgId
   * @param {string} dataSourceId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public handlersDatasourcehdlDataSourceHTTPHandlerDelete2(
    orgId: string,
    dataSourceId: string,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .handlersDatasourcehdlDataSourceHTTPHandlerDelete2(orgId, dataSourceId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary data Source HTTP Handler Get
   * @param {string} orgId
   * @param {string} dataSourceId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public handlersDatasourcehdlDataSourceHTTPHandlerGet(
    orgId: string,
    dataSourceId: string,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .handlersDatasourcehdlDataSourceHTTPHandlerGet(orgId, dataSourceId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary data Source HTTP Handler Get
   * @param {string} orgId
   * @param {string} dataSourceId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public handlersDatasourcehdlDataSourceHTTPHandlerGet2(
    orgId: string,
    dataSourceId: string,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .handlersDatasourcehdlDataSourceHTTPHandlerGet2(orgId, dataSourceId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary data Source HTTP Handler Get Big Query Table Pointer
   * @param {string} orgId
   * @param {string} dataSourceId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public handlersDatasourcehdlDataSourceHTTPHandlerGetBigQueryTablePointer(
    orgId: string,
    dataSourceId: string,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .handlersDatasourcehdlDataSourceHTTPHandlerGetBigQueryTablePointer(orgId, dataSourceId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary data Source HTTP Handler Get Big Query Table Pointer
   * @param {string} orgId
   * @param {string} dataSourceId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public handlersDatasourcehdlDataSourceHTTPHandlerGetBigQueryTablePointer2(
    orgId: string,
    dataSourceId: string,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .handlersDatasourcehdlDataSourceHTTPHandlerGetBigQueryTablePointer2(orgId, dataSourceId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary data Source HTTP Handler List
   * @param {string} orgId
   * @param {string} [matchesFeedSchemaType]
   * @param {string} [pageToken]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public handlersDatasourcehdlDataSourceHTTPHandlerList(
    orgId: string,
    matchesFeedSchemaType?: string,
    pageToken?: string,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .handlersDatasourcehdlDataSourceHTTPHandlerList(orgId, matchesFeedSchemaType, pageToken, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary data Source HTTP Handler List
   * @param {string} orgId
   * @param {string} [matchesFeedSchemaType]
   * @param {string} [pageToken]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public handlersDatasourcehdlDataSourceHTTPHandlerList2(
    orgId: string,
    matchesFeedSchemaType?: string,
    pageToken?: string,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .handlersDatasourcehdlDataSourceHTTPHandlerList2(orgId, matchesFeedSchemaType, pageToken, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary data Source HTTP Handler List Paginated
   * @param {string} orgId
   * @param {string} [matchesFeedSchemaType]
   * @param {string} [pageToken]
   * @param {number} [pageSize]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public handlersDatasourcehdlDataSourceHTTPHandlerListPaginated(
    orgId: string,
    matchesFeedSchemaType?: string,
    pageToken?: string,
    pageSize?: number,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .handlersDatasourcehdlDataSourceHTTPHandlerListPaginated(
        orgId,
        matchesFeedSchemaType,
        pageToken,
        pageSize,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary data Source HTTP Handler List Paginated
   * @param {string} orgId
   * @param {string} [matchesFeedSchemaType]
   * @param {string} [pageToken]
   * @param {number} [pageSize]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public handlersDatasourcehdlDataSourceHTTPHandlerListPaginated2(
    orgId: string,
    matchesFeedSchemaType?: string,
    pageToken?: string,
    pageSize?: number,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .handlersDatasourcehdlDataSourceHTTPHandlerListPaginated2(
        orgId,
        matchesFeedSchemaType,
        pageToken,
        pageSize,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary feed HTTP Handler Archive
   * @param {string} orgId Organization ID
   * @param {string} feedId Feed ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public handlersFeedhdlFeedHTTPHandlerArchive(orgId: string, feedId: string, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .handlersFeedhdlFeedHTTPHandlerArchive(orgId, feedId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary feed HTTP Handler Create
   * @param {string} orgId Organization ID
   * @param {DataCoreSvcFeedhdlCreateFeedInput} [dataCoreSvcFeedhdlCreateFeedInput]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public handlersFeedhdlFeedHTTPHandlerCreate(
    orgId: string,
    dataCoreSvcFeedhdlCreateFeedInput?: DataCoreSvcFeedhdlCreateFeedInput,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .handlersFeedhdlFeedHTTPHandlerCreate(orgId, dataCoreSvcFeedhdlCreateFeedInput, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary feed HTTP Handler Delete
   * @param {string} orgId Organization ID
   * @param {string} feedId Feed ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public handlersFeedhdlFeedHTTPHandlerDelete(orgId: string, feedId: string, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .handlersFeedhdlFeedHTTPHandlerDelete(orgId, feedId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary feed HTTP Handler Get
   * @param {string} orgId Organization ID
   * @param {string} feedId Feed ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public handlersFeedhdlFeedHTTPHandlerGet(orgId: string, feedId: string, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .handlersFeedhdlFeedHTTPHandlerGet(orgId, feedId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary feed HTTP Handler Get Feed Validation Status
   * @param {string} orgId Organization ID
   * @param {string} feedId Feed ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public handlersFeedhdlFeedHTTPHandlerGetFeedValidationStatus(
    orgId: string,
    feedId: string,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .handlersFeedhdlFeedHTTPHandlerGetFeedValidationStatus(orgId, feedId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary feed HTTP Handler List
   * @param {string} orgId Organization ID
   * @param {string} [pageToken]
   * @param {number} [pageSize]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public handlersFeedhdlFeedHTTPHandlerList(
    orgId: string,
    pageToken?: string,
    pageSize?: number,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .handlersFeedhdlFeedHTTPHandlerList(orgId, pageToken, pageSize, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary feed HTTP Handler Update Feed Approval
   * @param {string} orgId Organization ID
   * @param {string} feedId Feed ID
   * @param {DataCoreSvcFeedhdlUpdateFeedApprovalInput} [dataCoreSvcFeedhdlUpdateFeedApprovalInput]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public handlersFeedhdlFeedHTTPHandlerUpdateFeedApproval(
    orgId: string,
    feedId: string,
    dataCoreSvcFeedhdlUpdateFeedApprovalInput?: DataCoreSvcFeedhdlUpdateFeedApprovalInput,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .handlersFeedhdlFeedHTTPHandlerUpdateFeedApproval(
        orgId,
        feedId,
        dataCoreSvcFeedhdlUpdateFeedApprovalInput,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Feed Run Handler Init Manual Run
   * @param {string} orgId
   * @param {string} feedId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public handlersFeedrunhdlFeedRunHandlerInitManualRun(orgId: string, feedId: string, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .handlersFeedrunhdlFeedRunHandlerInitManualRun(orgId, feedId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Feed Run Handler List Runs
   * @param {string} orgId
   * @param {string} feedId
   * @param {object} [filter]
   * @param {string} [pageToken]
   * @param {number} [pageSize]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public handlersFeedrunhdlFeedRunHandlerListRuns(
    orgId: string,
    feedId: string,
    filter?: object,
    pageToken?: string,
    pageSize?: number,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .handlersFeedrunhdlFeedRunHandlerListRuns(orgId, feedId, filter, pageToken, pageSize, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Feed Run Handler Start Sync
   * @param {string} orgId
   * @param {string} feedId
   * @param {string} feedRunId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public handlersFeedrunhdlFeedRunHandlerStartSync(
    orgId: string,
    feedId: string,
    feedRunId: string,
    options?: AxiosRequestConfig
  ) {
    return DefaultApiFp(this.configuration)
      .handlersFeedrunhdlFeedRunHandlerStartSync(orgId, feedId, feedRunId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary import HTTP Handler Get
   * @param {string} orgId
   * @param {string} importId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public handlersImporthdlImportHTTPHandlerGet(orgId: string, importId: string, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .handlersImporthdlImportHTTPHandlerGet(orgId, importId, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * FeedRunApi - axios parameter creator
 * @export
 */
export const FeedRunApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Gets a feed run by ID
     * @summary Get Run
     * @param {string} orgId
     * @param {string} feedId
     * @param {string} feedRunId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRun: async (
      orgId: string,
      feedId: string,
      feedRunId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('getRun', 'orgId', orgId);
      // verify required parameter 'feedId' is not null or undefined
      assertParamExists('getRun', 'feedId', feedId);
      // verify required parameter 'feedRunId' is not null or undefined
      assertParamExists('getRun', 'feedRunId', feedRunId);
      const localVarPath = `/orgs/{orgId}/feeds/{feedId}/runs/{feedRunId}/`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'feedId'}}`, encodeURIComponent(String(feedId)))
        .replace(`{${'feedRunId'}}`, encodeURIComponent(String(feedRunId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Gets a summary of a feed run
     * @summary Get Run Summary
     * @param {string} orgId
     * @param {string} feedId
     * @param {string} feedRunId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRunSummary: async (
      orgId: string,
      feedId: string,
      feedRunId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('getRunSummary', 'orgId', orgId);
      // verify required parameter 'feedId' is not null or undefined
      assertParamExists('getRunSummary', 'feedId', feedId);
      // verify required parameter 'feedRunId' is not null or undefined
      assertParamExists('getRunSummary', 'feedRunId', feedRunId);
      const localVarPath = `/orgs/{orgId}/feeds/{feedId}/runs/{feedRunId}/summary`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'feedId'}}`, encodeURIComponent(String(feedId)))
        .replace(`{${'feedRunId'}}`, encodeURIComponent(String(feedRunId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * FeedRunApi - functional programming interface
 * @export
 */
export const FeedRunApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = FeedRunApiAxiosParamCreator(configuration);
  return {
    /**
     * Gets a feed run by ID
     * @summary Get Run
     * @param {string} orgId
     * @param {string} feedId
     * @param {string} feedRunId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getRun(
      orgId: string,
      feedId: string,
      feedRunId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataCoreSvcFeedrunhdlGetRunOutput>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getRun(orgId, feedId, feedRunId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Gets a summary of a feed run
     * @summary Get Run Summary
     * @param {string} orgId
     * @param {string} feedId
     * @param {string} feedRunId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getRunSummary(
      orgId: string,
      feedId: string,
      feedRunId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataCoreSvcFeedrunhdlGetRunSummaryOutput>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getRunSummary(orgId, feedId, feedRunId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * FeedRunApi - factory interface
 * @export
 */
export const FeedRunApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = FeedRunApiFp(configuration);
  return {
    /**
     * Gets a feed run by ID
     * @summary Get Run
     * @param {string} orgId
     * @param {string} feedId
     * @param {string} feedRunId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRun(
      orgId: string,
      feedId: string,
      feedRunId: string,
      options?: any
    ): AxiosPromise<DataCoreSvcFeedrunhdlGetRunOutput> {
      return localVarFp.getRun(orgId, feedId, feedRunId, options).then((request) => request(axios, basePath));
    },
    /**
     * Gets a summary of a feed run
     * @summary Get Run Summary
     * @param {string} orgId
     * @param {string} feedId
     * @param {string} feedRunId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRunSummary(
      orgId: string,
      feedId: string,
      feedRunId: string,
      options?: any
    ): AxiosPromise<DataCoreSvcFeedrunhdlGetRunSummaryOutput> {
      return localVarFp.getRunSummary(orgId, feedId, feedRunId, options).then((request) => request(axios, basePath));
    },
  };
};

/**
 * FeedRunApi - object-oriented interface
 * @export
 * @class FeedRunApi
 * @extends {BaseAPI}
 */
export class FeedRunApi extends BaseAPI {
  /**
   * Gets a feed run by ID
   * @summary Get Run
   * @param {string} orgId
   * @param {string} feedId
   * @param {string} feedRunId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FeedRunApi
   */
  public getRun(orgId: string, feedId: string, feedRunId: string, options?: AxiosRequestConfig) {
    return FeedRunApiFp(this.configuration)
      .getRun(orgId, feedId, feedRunId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Gets a summary of a feed run
   * @summary Get Run Summary
   * @param {string} orgId
   * @param {string} feedId
   * @param {string} feedRunId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FeedRunApi
   */
  public getRunSummary(orgId: string, feedId: string, feedRunId: string, options?: AxiosRequestConfig) {
    return FeedRunApiFp(this.configuration)
      .getRunSummary(orgId, feedId, feedRunId, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ImportApi - axios parameter creator
 * @export
 */
export const ImportApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Get a download link for the raw CSV file associated with an import
     * @summary Get CSV Download Link
     * @param {string} orgId
     * @param {string} importId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersImporthdlImportHTTPHandlerGetCsvLink: async (
      orgId: string,
      importId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('handlersImporthdlImportHTTPHandlerGetCsvLink', 'orgId', orgId);
      // verify required parameter 'importId' is not null or undefined
      assertParamExists('handlersImporthdlImportHTTPHandlerGetCsvLink', 'importId', importId);
      const localVarPath = `/orgs/{orgId}/imports/{importId}/csv-link`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'importId'}}`, encodeURIComponent(String(importId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Preview the results of an import operation
     * @summary Preview Import
     * @param {string} orgId Organization ID
     * @param {string} importId Import ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersImporthdlImportHTTPHandlerGetPreview: async (
      orgId: string,
      importId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('handlersImporthdlImportHTTPHandlerGetPreview', 'orgId', orgId);
      // verify required parameter 'importId' is not null or undefined
      assertParamExists('handlersImporthdlImportHTTPHandlerGetPreview', 'importId', importId);
      const localVarPath = `/orgs/{orgId}/imports/{importId}/preview`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'importId'}}`, encodeURIComponent(String(importId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Start a background sync operation for an import
     * @summary Sync Import
     * @param {string} orgId Organization ID
     * @param {string} importId Import ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersImporthdlImportHTTPHandlerGetSyncDetails: async (
      orgId: string,
      importId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('handlersImporthdlImportHTTPHandlerGetSyncDetails', 'orgId', orgId);
      // verify required parameter 'importId' is not null or undefined
      assertParamExists('handlersImporthdlImportHTTPHandlerGetSyncDetails', 'importId', importId);
      const localVarPath = `/orgs/{orgId}/imports/{importId}/sync`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'importId'}}`, encodeURIComponent(String(importId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Initialize a new import operation
     * @summary Initialize Import
     * @param {string} orgId Organization ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersImporthdlImportHTTPHandlerInitRun: async (
      orgId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('handlersImporthdlImportHTTPHandlerInitRun', 'orgId', orgId);
      const localVarPath = `/orgs/{orgId}/imports/init`.replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * List all imports for an organization
     * @summary List Imports
     * @param {string} orgId Organization ID
     * @param {string} [pageToken] Page token for pagination
     * @param {number} [pageSize] Page size for pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersImporthdlImportHTTPHandlerList: async (
      orgId: string,
      pageToken?: string,
      pageSize?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('handlersImporthdlImportHTTPHandlerList', 'orgId', orgId);
      const localVarPath = `/orgs/{orgId}/imports/`.replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (pageToken !== undefined) {
        localVarQueryParameter['pageToken'] = pageToken;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['pageSize'] = pageSize;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Start a background sync operation for an import
     * @summary Sync Import
     * @param {string} orgId Organization ID
     * @param {string} importId Import ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersImporthdlImportHTTPHandlerStartSync: async (
      orgId: string,
      importId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('handlersImporthdlImportHTTPHandlerStartSync', 'orgId', orgId);
      // verify required parameter 'importId' is not null or undefined
      assertParamExists('handlersImporthdlImportHTTPHandlerStartSync', 'importId', importId);
      const localVarPath = `/orgs/{orgId}/imports/{importId}/sync`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'importId'}}`, encodeURIComponent(String(importId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Validate the schema of an import file
     * @summary Validate Import Schema
     * @param {string} orgId Organization ID
     * @param {string} importId Import ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersImporthdlImportHTTPHandlerValidateSchema: async (
      orgId: string,
      importId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'orgId' is not null or undefined
      assertParamExists('handlersImporthdlImportHTTPHandlerValidateSchema', 'orgId', orgId);
      // verify required parameter 'importId' is not null or undefined
      assertParamExists('handlersImporthdlImportHTTPHandlerValidateSchema', 'importId', importId);
      const localVarPath = `/orgs/{orgId}/imports/{importId}/validate`
        .replace(`{${'orgId'}}`, encodeURIComponent(String(orgId)))
        .replace(`{${'importId'}}`, encodeURIComponent(String(importId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * ImportApi - functional programming interface
 * @export
 */
export const ImportApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ImportApiAxiosParamCreator(configuration);
  return {
    /**
     * Get a download link for the raw CSV file associated with an import
     * @summary Get CSV Download Link
     * @param {string} orgId
     * @param {string} importId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async handlersImporthdlImportHTTPHandlerGetCsvLink(
      orgId: string,
      importId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataCoreSvcPortsCsvLinkResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.handlersImporthdlImportHTTPHandlerGetCsvLink(
        orgId,
        importId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Preview the results of an import operation
     * @summary Preview Import
     * @param {string} orgId Organization ID
     * @param {string} importId Import ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async handlersImporthdlImportHTTPHandlerGetPreview(
      orgId: string,
      importId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataCoreSvcPortsPreviewImportResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.handlersImporthdlImportHTTPHandlerGetPreview(
        orgId,
        importId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Start a background sync operation for an import
     * @summary Sync Import
     * @param {string} orgId Organization ID
     * @param {string} importId Import ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async handlersImporthdlImportHTTPHandlerGetSyncDetails(
      orgId: string,
      importId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataCoreSvcPortsGetSyncImportResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.handlersImporthdlImportHTTPHandlerGetSyncDetails(
        orgId,
        importId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Initialize a new import operation
     * @summary Initialize Import
     * @param {string} orgId Organization ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async handlersImporthdlImportHTTPHandlerInitRun(
      orgId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataCoreSvcPortsInitImportResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.handlersImporthdlImportHTTPHandlerInitRun(
        orgId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * List all imports for an organization
     * @summary List Imports
     * @param {string} orgId Organization ID
     * @param {string} [pageToken] Page token for pagination
     * @param {number} [pageSize] Page size for pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async handlersImporthdlImportHTTPHandlerList(
      orgId: string,
      pageToken?: string,
      pageSize?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataCoreSvcPortsListImportsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.handlersImporthdlImportHTTPHandlerList(
        orgId,
        pageToken,
        pageSize,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Start a background sync operation for an import
     * @summary Sync Import
     * @param {string} orgId Organization ID
     * @param {string} importId Import ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async handlersImporthdlImportHTTPHandlerStartSync(
      orgId: string,
      importId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataCoreSvcPortsPostSyncImportResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.handlersImporthdlImportHTTPHandlerStartSync(
        orgId,
        importId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Validate the schema of an import file
     * @summary Validate Import Schema
     * @param {string} orgId Organization ID
     * @param {string} importId Import ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async handlersImporthdlImportHTTPHandlerValidateSchema(
      orgId: string,
      importId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DataCoreSvcPortsValidateImportResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.handlersImporthdlImportHTTPHandlerValidateSchema(
        orgId,
        importId,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * ImportApi - factory interface
 * @export
 */
export const ImportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = ImportApiFp(configuration);
  return {
    /**
     * Get a download link for the raw CSV file associated with an import
     * @summary Get CSV Download Link
     * @param {string} orgId
     * @param {string} importId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersImporthdlImportHTTPHandlerGetCsvLink(
      orgId: string,
      importId: string,
      options?: any
    ): AxiosPromise<DataCoreSvcPortsCsvLinkResponse> {
      return localVarFp
        .handlersImporthdlImportHTTPHandlerGetCsvLink(orgId, importId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Preview the results of an import operation
     * @summary Preview Import
     * @param {string} orgId Organization ID
     * @param {string} importId Import ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersImporthdlImportHTTPHandlerGetPreview(
      orgId: string,
      importId: string,
      options?: any
    ): AxiosPromise<DataCoreSvcPortsPreviewImportResponse> {
      return localVarFp
        .handlersImporthdlImportHTTPHandlerGetPreview(orgId, importId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Start a background sync operation for an import
     * @summary Sync Import
     * @param {string} orgId Organization ID
     * @param {string} importId Import ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersImporthdlImportHTTPHandlerGetSyncDetails(
      orgId: string,
      importId: string,
      options?: any
    ): AxiosPromise<DataCoreSvcPortsGetSyncImportResponse> {
      return localVarFp
        .handlersImporthdlImportHTTPHandlerGetSyncDetails(orgId, importId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Initialize a new import operation
     * @summary Initialize Import
     * @param {string} orgId Organization ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersImporthdlImportHTTPHandlerInitRun(
      orgId: string,
      options?: any
    ): AxiosPromise<DataCoreSvcPortsInitImportResponse> {
      return localVarFp
        .handlersImporthdlImportHTTPHandlerInitRun(orgId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * List all imports for an organization
     * @summary List Imports
     * @param {string} orgId Organization ID
     * @param {string} [pageToken] Page token for pagination
     * @param {number} [pageSize] Page size for pagination
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersImporthdlImportHTTPHandlerList(
      orgId: string,
      pageToken?: string,
      pageSize?: number,
      options?: any
    ): AxiosPromise<DataCoreSvcPortsListImportsResponse> {
      return localVarFp
        .handlersImporthdlImportHTTPHandlerList(orgId, pageToken, pageSize, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Start a background sync operation for an import
     * @summary Sync Import
     * @param {string} orgId Organization ID
     * @param {string} importId Import ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersImporthdlImportHTTPHandlerStartSync(
      orgId: string,
      importId: string,
      options?: any
    ): AxiosPromise<DataCoreSvcPortsPostSyncImportResponse> {
      return localVarFp
        .handlersImporthdlImportHTTPHandlerStartSync(orgId, importId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Validate the schema of an import file
     * @summary Validate Import Schema
     * @param {string} orgId Organization ID
     * @param {string} importId Import ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handlersImporthdlImportHTTPHandlerValidateSchema(
      orgId: string,
      importId: string,
      options?: any
    ): AxiosPromise<DataCoreSvcPortsValidateImportResponse> {
      return localVarFp
        .handlersImporthdlImportHTTPHandlerValidateSchema(orgId, importId, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * ImportApi - object-oriented interface
 * @export
 * @class ImportApi
 * @extends {BaseAPI}
 */
export class ImportApi extends BaseAPI {
  /**
   * Get a download link for the raw CSV file associated with an import
   * @summary Get CSV Download Link
   * @param {string} orgId
   * @param {string} importId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ImportApi
   */
  public handlersImporthdlImportHTTPHandlerGetCsvLink(orgId: string, importId: string, options?: AxiosRequestConfig) {
    return ImportApiFp(this.configuration)
      .handlersImporthdlImportHTTPHandlerGetCsvLink(orgId, importId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Preview the results of an import operation
   * @summary Preview Import
   * @param {string} orgId Organization ID
   * @param {string} importId Import ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ImportApi
   */
  public handlersImporthdlImportHTTPHandlerGetPreview(orgId: string, importId: string, options?: AxiosRequestConfig) {
    return ImportApiFp(this.configuration)
      .handlersImporthdlImportHTTPHandlerGetPreview(orgId, importId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Start a background sync operation for an import
   * @summary Sync Import
   * @param {string} orgId Organization ID
   * @param {string} importId Import ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ImportApi
   */
  public handlersImporthdlImportHTTPHandlerGetSyncDetails(
    orgId: string,
    importId: string,
    options?: AxiosRequestConfig
  ) {
    return ImportApiFp(this.configuration)
      .handlersImporthdlImportHTTPHandlerGetSyncDetails(orgId, importId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Initialize a new import operation
   * @summary Initialize Import
   * @param {string} orgId Organization ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ImportApi
   */
  public handlersImporthdlImportHTTPHandlerInitRun(orgId: string, options?: AxiosRequestConfig) {
    return ImportApiFp(this.configuration)
      .handlersImporthdlImportHTTPHandlerInitRun(orgId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * List all imports for an organization
   * @summary List Imports
   * @param {string} orgId Organization ID
   * @param {string} [pageToken] Page token for pagination
   * @param {number} [pageSize] Page size for pagination
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ImportApi
   */
  public handlersImporthdlImportHTTPHandlerList(
    orgId: string,
    pageToken?: string,
    pageSize?: number,
    options?: AxiosRequestConfig
  ) {
    return ImportApiFp(this.configuration)
      .handlersImporthdlImportHTTPHandlerList(orgId, pageToken, pageSize, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Start a background sync operation for an import
   * @summary Sync Import
   * @param {string} orgId Organization ID
   * @param {string} importId Import ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ImportApi
   */
  public handlersImporthdlImportHTTPHandlerStartSync(orgId: string, importId: string, options?: AxiosRequestConfig) {
    return ImportApiFp(this.configuration)
      .handlersImporthdlImportHTTPHandlerStartSync(orgId, importId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Validate the schema of an import file
   * @summary Validate Import Schema
   * @param {string} orgId Organization ID
   * @param {string} importId Import ID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ImportApi
   */
  public handlersImporthdlImportHTTPHandlerValidateSchema(
    orgId: string,
    importId: string,
    options?: AxiosRequestConfig
  ) {
    return ImportApiFp(this.configuration)
      .handlersImporthdlImportHTTPHandlerValidateSchema(orgId, importId, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
